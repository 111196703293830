import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../../../hooks/useFetching";
import ProgressService from "../../../../../../api/ProgressService";
import MyModal from "../../../../../../components/UI/MyModal/MyModal";
import {
  Button,
  CloseButton,
  Input,
  ToggleButton,
} from "../../../../../../teacherComponents";
import cl from "./CreateWordsCategoryModal.module.css";
import { LanguageLevels } from "../../../../../../data/common";

const CreateWordsCategoryModal = ({ visible, setVisible, lang, onAdd }) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [difficulty, setDifficulty] = useState("A1");

  const [createCategory, createLoading] = useFetching(async () => {
    const { data } = await ProgressService.createWordsCategory({
      title,
      lang,
      level: LanguageLevels.indexOf(difficulty),
    });
    if (data) {
      setVisible(false);
      setTitle("");
      onAdd(data);
    }
  });

  useEffect(() => {
    setTitle("");
  }, [visible]);

  return (
    <MyModal
      className={cl.generateModal}
      visible={visible}
      setVisible={setVisible}
    >
      <p className={cl.title}>{t("exercises.create_words_category")}</p>
      <p className={cl.subtitle}>{t("exercises.create_words_category_desc")}</p>
      <Input
        placeholder={t("exercises.words_category_title")}
        variant={"outlined"}
        value={title}
        onChange={setTitle}
      />
      <ToggleButton
        style={{ width: 212 }}
        label={t("users.level")}
        options={LanguageLevels}
        selectedOption={difficulty}
        variant={"filled"}
        setOption={setDifficulty}
      />
      <Button
        variant={"dark"}
        text={t("buttons.add")}
        onClick={createCategory}
        isDisabled={!title || !difficulty}
        isLoading={createLoading}
        style={{ margin: "0 auto", marginTop: 30 }}
      />
      <CloseButton onClick={() => setVisible(false)} />
    </MyModal>
  );
};

export default CreateWordsCategoryModal;
