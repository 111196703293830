import React from "react";
import { ReactComponent as IconDictionary } from "../../../../assets/svg/lessons-dictionary.svg";
import { ReactComponent as IconExercise } from "../../../../assets/svg/lessons-exercise.svg";
import { ReactComponent as IconSituation } from "../../../../assets/svg/lessons-situation.svg";
import { ReactComponent as IconTrack } from "../../../../assets/svg/lessons-track.svg";
import { ReactComponent as IconFile } from "../../../../assets/svg/lessons-file.svg";
import { Chapters } from "../../data/constants";
import cl from "./ChapterPreview.module.css";

const ChapterPreview = ({ chapter, variant }) => {
  const { title, type } = chapter;

  const iconMap = {
    [Chapters.Track]: <IconTrack />,
    [Chapters.Situation]: <IconSituation />,
    [Chapters.Exercise]: <IconExercise />,
    [Chapters.Dictionary]: <IconDictionary />,
    [Chapters.File]: <IconFile />,
  };

  return (
    <div className={`${cl.chapter} ${cl[variant]}`}>
      <div className={cl.icon}>{iconMap[type]}</div>
      <p className={cl.title}>{title}</p>
    </div>
  );
};

export default ChapterPreview;
