import React from "react";
import { useTranslation } from "react-i18next";
import cl from "./StudentCard.module.css";

const StudentCard = ({ student, setCurrent, isActive, notifications }) => {
  const { t } = useTranslation();

  const { name, level, profilePic } = student;
  
  const renderNotification = () => {
    if (!notifications || !notifications?.exercises?.length) return null;
    return (
      <div className={cl.notification}>{notifications.exercises.length}</div>
    );
  };

  return (
    <div
      className={`${cl.studentCard} ${isActive ? cl.active : ""}`}
      onClick={() => {
        setCurrent && setCurrent(student);
      }}
    >
      <div className={cl.cardInner}>
        <div className={cl.profilePic}>
          <p>{profilePic}</p>
        </div>
        <div>
          <div className={cl.titleCont}>
            <p className={cl.name}>{name}</p>
            {renderNotification()}
          </div>

          <p className={cl.level}>{level}</p>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
