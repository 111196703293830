import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../teacherComponents";
import cl from "./StudentNotes.module.css";

const StudentNotes = ({ name, comment, onChange }) => {
  const { t } = useTranslation();
  const ref = useRef();

  const [focus, setFocus] = useState(false);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  useEffect(() => {
    if (focus) ref.current?.focus();
    if (!focus) ref.current?.blur();
  }, [focus]);

  return (
    <>
      <p className={cl.name}>{name}</p>
      <textarea
        className={cl.note}
        ref={ref}
        placeholder={t("exercises.comments")}
        onChange={handleChange}
        value={comment ?? ""}
        autoFocus={focus}
        onFocus={() => setFocus(true)}
        rows={1}
      ></textarea>
      <Button
        variant={focus ? "dark" : "grey"}
        text={t(focus ? "buttons.save" : "buttons.edit")}
        onClick={() => setFocus(!focus)}
      />
    </>
  );
};

export default StudentNotes;
