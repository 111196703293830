import { makeAutoObservable } from "mobx";

class StudentsStore {
  currentStudent = null;
  currentStudentChapters = {};

  notifications = [];

  age = "";
  difficulty = "";
  searchString = "";
  studentSearch = "";

  collapseCards = true;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true, deep: true });
  }

  setCurrentStudent(currentStudent) {
    this.currentStudent = currentStudent;
  }

  setCurrentStudentChapters(currentStudentChapters) {
    this.currentStudentChapters = currentStudentChapters;
  }

  setNotifications(notifications) {
    this.notifications = notifications;
  }

  setSearchString(searchString) {
    this.searchString = searchString;
  }

  setStudentSearch(studentSearch) {
    this.studentSearch = studentSearch;
  }

  setDifficulty(difficulty) {
    this.difficulty = difficulty;
  }

  setAge(age) {
    this.age = age;
  }

  toggleCollapseCards() {
    this.collapseCards = !this.collapseCards;
  }
}

const studentsStore = new StudentsStore();
export default studentsStore;
