import React, { useEffect, useState } from "react";
import cl from "./QuizPreview.module.css";
import QuestionsLabel from "../QuestionsLabel/QuestionsLabel";

const QuizPreview = ({ exerciseObj, results, showAnswers }) => {
  const { data } = exerciseObj;
  const { questions } = data;
  const [answers, setAnswers] = useState(new Array(questions?.length).fill(""));

  const handleAnswerClick = (qIdx, answer) => {
    const newAnswers = [...answers];
    newAnswers[qIdx] = newAnswers[qIdx] === answer ? "" : answer;
    setAnswers(newAnswers);
  };

  const displayAnswers = () => {
    setAnswers(questions?.map((q) => q.answer));
  };

  const resetAnswers = () => {
    if (results) return;
    setAnswers(new Array(questions?.length).fill(""));
  };

  const getAnswerClassName = (questionIdx, option) => {
    const question = questions[questionIdx];
    const classNames = [cl.answer];
    if (results || showAnswers) {
      classNames.push(cl.blocked);
      if (answers[questionIdx] && answers[questionIdx] === option.text)
        classNames.push(
          answers[questionIdx] === question.answer ? cl.correct : cl.wrong
        );
    } else {
      if (answers[questionIdx] === option.text) classNames.push(cl.active);
    }
    return classNames.join(" ");
  };

  useEffect(() => {
    showAnswers ? displayAnswers() : resetAnswers();
  }, [showAnswers]);

  useEffect(() => {
    if (results && results.userAnswers) {
      setAnswers(
        questions.map((q, i) => q.options[results.userAnswers[i]].text)
      );
    }
  }, [results]);

  if (!questions) return <></>;

  return (
    <>
      <QuestionsLabel label={results ? "student_answers" : "questions"} />
      <div className={cl.questionsCont}>
        {questions?.map((q, qIdx) => (
          <div className={cl.question} key={q.id}>
            <p className={cl.text}>{`${qIdx + 1}. ${q.text}`}</p>
            <div className={cl.answersCont}>
              {q.options.map((o) => (
                <span
                  className={getAnswerClassName(qIdx, o)}
                  key={o.id}
                  onClick={
                    results ? () => {} : () => handleAnswerClick(qIdx, o.text)
                  }
                >
                  {o.text}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default QuizPreview;
