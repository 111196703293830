import React from "react";
import { useTranslation } from "react-i18next";
import GapWord from "../GapWord/GapWord";
import cl from "./ClozeEditor.module.css";
import { QuestionsLabel } from "../../../Preview/components";

const ClozeEditor = ({ exerciseData, onChange }) => {
  const { t } = useTranslation();

  const { sentences } = exerciseData;

  const handleWordToggle = (sentenceId, wordId) => {
    onChange({
      sentences: sentences.map((s) =>
        s.id === sentenceId
          ? {
              ...s,
              words: s.words.map((w) =>
                w.id === wordId ? { ...w, hidden: !w.hidden } : w
              ),
            }
          : s
      ),
    });
  };

  return (
    <div className={cl.textCont}>
      <QuestionsLabel label={"gap_word_select"} />
      <div className={cl.exerciseText}>
        {sentences?.map((s) => (
          <div key={s.id} className={cl.sentence}>
            {s.words.map((word) => (
              <GapWord
                key={word.id}
                word={word}
                onChange={() => handleWordToggle(s.id, word.id)}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClozeEditor;
