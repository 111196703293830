import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import exercisesPlayer from "../../../../store/exercisesPlayer";
import cl from "./ExercisePlayer.module.css";
const YTPlayer = require("yt-player");

const TICKS_IN_SECOND = 10000000;

const ExercisePlayer = ({ isPreview, isControls }) => {
  const ref = useRef(null);
  if (isControls) {
    exercisesPlayer.setCurrentTime(0);
  }
  useEffect(() => {
    exercisesPlayer.setIsLoaded(false);
    ref.current = new YTPlayer("#YTPlayer", {
      captions: false,
      controls: isControls,
      modestBranding: true,
      timeupdateFrequency: 100,
      playsInline: true,
      fullscreen: false,
      annotations: false,
      rel: 0,
      related: true,
      disablekb: 0,
      iv_load_policy: 3,
      showinfo: 0,
      enablejsapi: 1,
    });
    onPlayerReady();
    return () => {
      exercisesPlayer.playerRef?.destroy();
      ref.current?.destroy();
      exercisesPlayer.resetVideoParams(isPreview);
    };
  }, []);

  const onPlayerReady = () => {
    ref.current.load(
      exercisesPlayer.externalId,
      false,
      exercisesPlayer.currentTime / TICKS_IN_SECOND
    );
    ref.current.on("cued", () => {
      exercisesPlayer.setTrackLength(
        ref.current.getDuration() * TICKS_IN_SECOND
      );
      exercisesPlayer.setIsLoaded(true);
    });
    ref.current.on("paused", () => {
      exercisesPlayer.setIsPlaying(false);
    });
    ref.current.on("ended", () => {
      exercisesPlayer.setIsPlaying(false);
    });
    ref.current.on("playing", () => {
      exercisesPlayer.setIsPlaying(true);
    });
    ref.current.off("timeupdate", handleOnProgress);
    ref.current.on("timeupdate", handleOnProgress);
    ref.current.seek(exercisesPlayer.currentTime / TICKS_IN_SECOND);
    exercisesPlayer.setPlayerRef(ref.current);
    exercisesPlayer.setIsPlaying(false);
  };

  const handleOnProgress = (time) => {
    if (time === 0) return;
    exercisesPlayer.handleVideoPlay(time);
  };

  return (
    <div className={cl.container}>
      <div className={cl.ytContainer} id="YTPlayer" />
    </div>
  );
};

export default observer(ExercisePlayer);
