import React from "react";
import { useLocation } from "react-router";
import { auth } from "../../../App";
import { Roles } from "../../../data/common";
import cl from "./AppWrapper.module.css";

const WRAPPER_EXCLUDED_PAGES = [
  "/",
  "/fr",
  "/en",
  "/de",
  "/it",
  "/pt",
  "/es",
  "/ru",
];

const AppWrapper = ({ children }) => {
  const location = useLocation();
  const { role } = auth;
  
  const hideWrapper = WRAPPER_EXCLUDED_PAGES.includes(location.pathname);

  if (hideWrapper) return <>{children}</>;

  return (
    <div
      className={`${cl.AppWrapper} ${role === Roles.Teacher ? cl.teacher : ""}`}
      id={"appWrapper"}
    >
      {children}
    </div>
  );
};

export default AppWrapper;
