import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import exercisesStore from "../../store/exercisesStore";
import lessonsStore from "../../../TeacherLessons/store/lessonsStore";
import UserService from "../../../../api/UserService";
import ProgressService from "../../../../api/ProgressService";
import { LanguageLevels } from "../../../../data/common";
import cl from "./StudentsSelect.module.css";

const StudentsSelect = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const ref = useRef();

  const { currentStudent } = exercisesStore;

  const [expanded, setExpanded] = useState(false);
  const [studentOptions, setStudentsOptions] = useState();

  const [getStudents] = useFetching(async () => {
    const { data } = await UserService.getStudents();
    const options = data.items.map((s) => ({
      id: s.id,
      name: s.name?.length ? s.name : t("exercises.unnamed_student"),
      level: LanguageLevels[s.level],
      nativeLang: s.nativeLang,
      targetLang: s.lang,
    }));

    setStudentsOptions(options);
  });

  const [getStudentChapters, chaptersLoading] = useFetching(async () => {
    const userId = currentStudent?.id;

    const { data: trackData } = await ProgressService.getStudentTracks({
      userId,
      lang,
    });

    const { data: exerciseData } = await ProgressService.getStudentExercises({
      userId,
      lang,
    });

    const { data: situationData } = await ProgressService.getStudentSituations({
      userId,
      lang,
    });

    const { data: wordData } = await ProgressService.getStudentDictionaries({
      userId,
      lang,
    });

    const { data: lessonsData } = await ProgressService.getStudentLessons({
      userId,
      lang,
      status: "unfinished",
    });

    exercisesStore.setCurrentStudentChapters({
      situations: situationData?.items ?? [],
      tracks: trackData?.items ?? [],
      exercises: exerciseData?.items ?? [],
      dictionaries: wordData?.items ?? [],
      words: [],
    });
    lessonsStore.setCurrentStudentLessons(lessonsData?.items ?? []);
  });

  const handleOptionClick = (sObj) => {
    exercisesStore.setCurrentStudent(sObj);
    setExpanded(false);
  };

  const handleResetClick = () => {
    if (!currentStudent?.id) return;
    exercisesStore.setCurrentStudent(null);
    lessonsStore.setCurrentStudentLessons([]);
  };

  const getDisplayedValue = () => {
    if (currentStudent?.id) {
      const current =
        studentOptions?.find((s) => s.id === currentStudent?.id) ?? {};
      return <p className={cl.currentName}>{current.name}</p>;
    }
    return t("exercises.select_student");
  };

  const getClassName = (sObj) => {
    const classNames = [cl.optionCont];
    const { difficulty, lang } = exercisesStore;

    if (currentStudent?.id === sObj.id) classNames.push(cl.active);

    if (difficulty !== sObj.level || lang !== sObj.targetLang) {
      classNames.push(cl.wrong);
    }

    return classNames.join(" ");
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    getStudents();
  }, []);

  useEffect(() => {
    if (currentStudent) {
      getStudentChapters();
    }
  }, [currentStudent]);

  useEffect(() => {
    if (!currentStudent && !chaptersLoading) {
      exercisesStore.setCurrentStudentChapters({});
      lessonsStore.setCurrentStudentLessons([]);
    }
  }, [chaptersLoading, currentStudent]);

  return (
    <div
      className={`${cl.select} ${currentStudent ? cl.active : ""}`}
      onClick={() => setExpanded(!expanded)}
      ref={ref}
    >
      {!expanded ? (
        <>
          <div className={cl.arrowDown} />
          {
            <div
              className={cl.optionCont}
              style={{ background: "none", padding: 0 }}
            >
              {getDisplayedValue()}
            </div>
          }
        </>
      ) : (
        <>
          <div className={cl.arrowUp} />
          <div
            className={cl.optionCont}
            style={{ background: "none", padding: 0 }}
          >
            {getDisplayedValue()}
          </div>

          {studentOptions.length ? (
            <div className={cl.options}>
              {currentStudent?.id && (
                <div className={cl.resetOption} onClick={handleResetClick}>
                  <p>{t("buttons.reset")}</p>
                </div>
              )}
              {studentOptions.map((sObj) => (
                <div
                  className={getClassName(sObj)}
                  key={sObj.id}
                  onClick={() => handleOptionClick(sObj)}
                >
                  <p>{sObj.name}</p>
                  <div>{sObj.level ?? "?"}</div>
                </div>
              ))}
            </div>
          ) : (
            <p className={cl.emptyList} onClick={(e) => e.stopPropagation()}>
              {t("exercises.empty_students_list")}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default observer(StudentsSelect);
