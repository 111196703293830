import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import exercisesStore from "../../../../store/exercisesStore";
import { useFetching } from "../../../../../../hooks/useFetching";
import ExerciseService from "../../../../../../api/ExerciseService";
import {
  SubstitutionPreview,
  MultichoicePreview,
  ClozePreview,
  SentenceOrderPreview,
  QuizPreview,
  WordsOrderPreview,
} from "../Preview/components";
import {
  Button,
  Input,
  Range,
  ToggleButton,
} from "../../../../../../teacherComponents";
import GenerateText from "../GenerateText/GenerateText";
import {
  GrammarExerciseTypes,
  MediaExerciseTypes,
  MediaTypes,
  QuestionsRange,
} from "../../../../data/constants";
import { LanguageLevels } from "../../../../../../data/common";
import cl from "./TextExerciseOptions.module.css";

const TextExerciseOptions = ({ options, setOptions, onCreate }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { generatedText } = exercisesStore;

  const [exerciseObj, setExerciseObj] = useState();
  const [preview, setPreview] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [topicError, setTopicError] = useState(false);

  const { topic, type, difficulty, sentencesAmount } = options;

  const setTopic = (topic) => setOptions({ ...options, topic });
  const setDifficulty = (difficulty) => setOptions({ ...options, difficulty });

  const setSentencesAmount = (e) =>
    setOptions({ ...options, sentencesAmount: e.target.value });

  const [getExample, exampleLoading] = useFetching(async () => {
    const { data: listData } = await ExerciseService.getExercises({
      offset: 0,
      limit: 1,
      type,
    });
    if (listData && listData.items && listData.items) {
      const { data: exerciseData } = await ExerciseService.getExercise({
        id: listData.items[0].id,
      });
      setExerciseObj(exerciseData);
    }
  });

  const [getChatResponse, isChatLoading, chatError, resetError] = useFetching(
    async () => {
      const { data } = await ExerciseService.createExercise({
        ...options,
        difficulty: LanguageLevels.indexOf(difficulty),
        text: exercisesStore.generatedText,
        lang,
      });
      if (data) {
        onCreate(data);
      }
    }
  );

  const isRange = () =>
    generatedText !== null &&
    [GrammarExerciseTypes.StatementsTransformation].includes(options.type);

  const isGap = () =>
    generatedText !== null &&
    [
      GrammarExerciseTypes.Cloze,
      GrammarExerciseTypes.Multichoice,
      GrammarExerciseTypes.Substitution,
    ].includes(options.type);

  const getPreview = () => {
    switch (type) {
      case GrammarExerciseTypes.Multichoice:
        return <MultichoicePreview exerciseObj={exerciseObj} />;
      case GrammarExerciseTypes.Substitution:
        return <SubstitutionPreview exerciseObj={exerciseObj} />;
      case GrammarExerciseTypes.Cloze:
        return <ClozePreview exerciseObj={exerciseObj} />;
      case MediaExerciseTypes.Quiz:
        return <QuizPreview exerciseObj={exerciseObj} />;
      case MediaExerciseTypes.SentencesOrder:
        return <SentenceOrderPreview exerciseObj={exerciseObj} />;
      case GrammarExerciseTypes.WordsOrder:
        return <WordsOrderPreview exerciseObj={exerciseObj} />;
      default:
        return <></>;
    }
  };

  const handleExerciseGenerate = () => {
    if (!topic) {
      setTopicError(true);
      return;
    }

    getChatResponse();
  };

  useEffect(() => {
    setExerciseObj();
    getExample();
    setPreview(false);
  }, [options.type]);

  useEffect(() => {
    setTopicError(false);
  }, [options]);

  useEffect(() => {
    setErrorMessage(chatError?.message);
  }, [chatError]);

  return (
    <div className={cl.exerciseOptions}>
      <div
        className={cl.inputsContainer}
        style={{ justifyContent: "space-between" }}
      >
        {generatedText !== null && (
          <>
            <div>
              <p className={cl.label}>{t("exercises.exercise_level")}</p>
              <ToggleButton
                style={{ width: 212 }}
                label={t("users.level")}
                options={LanguageLevels}
                selectedOption={options.difficulty}
                variant={"filled"}
                setOption={setDifficulty}
              />
            </div>
            <p className={cl.label} style={{ marginBottom: -12 }}>
              {t("exercises.exercise_text")}
            </p>
          </>
        )}
        <GenerateText mediaType={MediaTypes.GeneratedText} />
      </div>

      {isRange() && (
        <div className={cl.inputContainer}>
          <p className={cl.label}>{`${t(
            type === MediaExerciseTypes.SentencesOrder
              ? "exercises.sentence_count"
              : "exercises.question_count"
          )} (${sentencesAmount ?? QuestionsRange.Default})`}</p>
          <Range
            style={{ marginTop: "auto" }}
            value={sentencesAmount ?? QuestionsRange.Default}
            min={QuestionsRange.Min}
            step={QuestionsRange.Step}
            max={QuestionsRange.Max}
            onChange={setSentencesAmount}
          />
        </div>
      )}

      {isGap() && (
        <div>
          <p className={cl.label}>{t("exercises.theme")}</p>
          <Input
            placeholder={"exercises.grammar_example"}
            variant={topicError ? "erroredSmall" : "outlinedSmall"}
            value={topic}
            onChange={setTopic}
          />
        </div>
      )}

      {errorMessage ? (
        <div className={cl.optionsContainer}>
          <p className={cl.errorMessage}>{`${t(
            "dictionary_administration.generation_error"
          )} (${errorMessage})`}</p>
          <Button
            text={t("buttons.reset")}
            variant={"red"}
            onClick={resetError}
          />
        </div>
      ) : (
        generatedText !== null && (
          <div className={cl.optionsContainer}>
            <Button
              text={t("exercises.generate_exercise")}
              variant={"purple"}
              onClick={handleExerciseGenerate}
              isLoading={isChatLoading}
            />
            {/* <Button
              variant={"grey"}
              text={t("demo_page.example")}
              onClick={() => setPreview(!preview)}
              isLoading={exampleLoading}
            /> */}
          </div>
        )
      )}
      {exerciseObj?.id && preview && (
        <div className={cl.preview}>{getPreview()}</div>
      )}
    </div>
  );
};

export default observer(TextExerciseOptions);
