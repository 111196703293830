import React, { useEffect, useRef, useState } from "react";
import { Droppable } from "@hello-pangea/dnd";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import ExerciseService from "../../../../api/ExerciseService";
import lessonsStore from "../../store/lessonsStore";
import teachersStore from "../../../Teachers/store/teachersStore";
import exercisesStore from "../../../TeacherContent/store/exercisesStore";
import { Views } from "../../data/constants";
import { PrimaryInput } from "../../../../UI";
import { AddLesson, DeleteConfirmation, Lesson } from "..";
import cl from "./Theme.module.css";

const Theme = ({ themeObj }) => {
  const { t } = useTranslation();

  const actionsRef = useRef();
  const lessonsRef = useRef();

  const { id, title, lessons = [] } = themeObj;
  const { currentTeacher } = teachersStore;
  const isTeacher = exercisesStore.view === Views.Teachers && currentTeacher;

  const [isEdit, setIsEdit] = useState(false);
  const [isActions, setIsActions] = useState(false);
  const [isAddLesson, setIsAddLesson] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [newTitle, setNewTitle] = useState(title);

  const [updateLesson] = useFetching(async ({ id, topic }) => {
    await ExerciseService.updateLesson({ id, topic });
  });

  const [deleteAllLessons] = useFetching(async () => {
    lessonsStore.deleteTheme(themeObj.id);
  });

  const handleTitleUpdate = () => {
    setIsEdit(false);
    if (!newTitle) return;
    lessonsStore.updateTheme({ ...themeObj, title: newTitle });
    lessons.forEach((l) => updateLesson({ id: l.id, topic: newTitle }));
  };

  const handleTitleChange = () => {
    if (isTeacher) return;
    setIsEdit(true);
  };

  const handleActionsClick = (e) => {
    e.preventDefault();
    setIsActions(!isActions);
  };

  const handleLessonAdd = () => {
    setIsAddLesson(true);
    lessonsRef.current.scrollTo();
    setIsActions(false);
  };

  const handleThemeDelete = () => {
    setIsDelete(true);
    setIsActions(false);
  };

  useEffect(() => {
    setIsActions(false);
  }, [isEdit]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setIsActions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionsRef]);

  return (
    <Droppable droppableId={id}>
      {(droppableProvided) => (
        <div
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          className={cl.theme}
        >
          {isEdit ? (
            <PrimaryInput
              value={newTitle}
              onChange={setNewTitle}
              onBlur={handleTitleUpdate}
              autoFocus={true}
            />
          ) : (
            <>
              <p className={cl.title} onClick={handleTitleChange}>
                {title}
              </p>
              <div className={cl.actionButton} onClick={handleActionsClick}>
                <span /> <span /> <span />
              </div>
              {isActions && (
                <div className={cl.actionsList} ref={actionsRef}>
                  <p onClick={handleLessonAdd}>{t("exercises.add_lesson")}</p>
                  <p onClick={handleTitleChange}>{t("exercises.rename")}</p>
                  <p onClick={handleThemeDelete}>
                    {t("exercises.delete_lessons")}
                  </p>
                </div>
              )}
            </>
          )}

          <div
            className={`${cl.lessons} ${isEdit || isActions ? cl.locked : ""}`}
            ref={lessonsRef}
          >
            {lessons.map((lesson, index) => (
              <Lesson
                key={lesson.id}
                lessonObj={lesson}
                index={index}
                themeId={id}
              />
            ))}

            {droppableProvided.placeholder}
          </div>
          {!isTeacher && (
            <AddLesson themeObj={themeObj} forceAdd={isAddLesson} />
          )}
          <DeleteConfirmation
            title={t(`exercises.delete_theme_confirmation_title`)}
            visible={isDelete}
            setVisible={setIsDelete}
            onDelete={deleteAllLessons}
          />
        </div>
      )}
    </Droppable>
  );
};

export default observer(Theme);
