import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TeacherUrl } from "./data/constants";

const FollowTeacher = () => {
  const location = useLocation();

  useEffect(() => {
    window.location.href = `${TeacherUrl}${location.search}`;
  }, []);

  return <></>;
};

export default FollowTeacher;
