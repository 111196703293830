import React from "react";
import { Droppable } from "@hello-pangea/dnd";
import { observer } from "mobx-react-lite";
import { StudentLesson } from "..";
import cl from "./StudentTheme.module.css";

const StudentTheme = ({ themeObj }) => {
  const { id, title, lessons } = themeObj;

  return (
    <Droppable droppableId={id}>
      {(droppableProvided) => (
        <div
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          className={cl.theme}
        >
          <p className={cl.title}>{title}</p>
          <div className={cl.lessons}>
            {lessons.map((lesson, index) => (
              <StudentLesson
                key={`${lesson.title}_${lesson.updatedAt}`}
                lessonObj={lesson}
                index={index}
                themeId={id}
              />
            ))}
            {droppableProvided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default observer(StudentTheme);
