import React, { useEffect, useRef, useState } from "react";
import cl from "./MyInput.module.css";
import { ReactComponent as IconEye } from "../../../assets/img/icon-eye.svg";
import { ReactComponent as IconEyeCrossed } from "../../../assets/img/icon-eye_crossed.svg";

const MyInput = React.forwardRef(
  (
    {
      placeholder,
      value,
      onChange,
      onEnterKey,
      onBlur,
      style,
      type,
      color,
      disabled = false,
    },
    ref
  ) => {
    const [showSymbols, setShowSymbols] = useState(
      type === "password" ? false : true
    );
    const inputRef = useRef(null);
    const classNames = [cl.myInput];

    if (color) {
      color = Array.isArray(color) ? color : color.split(" ");
      color.forEach((el) => {
        classNames.push(cl[el]);
      });
    }

    if (type === "textarea") {
      return (
        <textarea
          disabled={disabled}
          style={style}
          ref={ref}
          placeholder={placeholder}
          value={value}
          type={type || "text"}
          className={classNames.join(" ")}
          onChange={(e) => onChange(e.target.value, e)}
        />
      );
    }

    if (type === "password") {
      return (
        <div style={{ position: "relative" }}>
          <input
            ref={ref}
            disabled={disabled}
            style={style}
            placeholder={placeholder}
            value={value}
            type={showSymbols ? "text" : "password"}
            className={classNames.join(" ")}
            onChange={(e) => onChange(e.target.value, e)}
          />
          {showSymbols ? (
            <IconEye
              className={cl.eyeStyle}
              onClick={() => {
                setShowSymbols((prev) => !prev);
              }}
            />
          ) : (
            <IconEyeCrossed
              className={cl.eyeStyle}
              onClick={() => {
                setShowSymbols((prev) => !prev);
              }}
            />
          )}
        </div>
      );
    }

    return (
      <input
        ref={ref}
        disabled={disabled}
        style={style}
        placeholder={placeholder}
        value={value}
        type={type || "text"}
        className={classNames.join(" ")}
        onChange={(e) => onChange(e.target.value, e)}
        onKeyDown={onEnterKey}
        onBlur={onBlur}
      />
    );
  }
);

export default MyInput;
