import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import teachersStore from "../../store/teachersStore";
import { Button } from "../../../../teacherComponents";
import { ReactComponent as IconStar } from "../../../../assets/svg/lessons-star.svg";
import cl from "./TeacherCard.module.css";

const TeacherCard = ({ teacher }) => {
  const { t } = useTranslation();
  const { name } = teacher;

  const handleClick = () => {
    teachersStore.setCurrentTeacher(teacher);
  };

  return (
    <div className={cl.teacherCard} onClick={handleClick}>
      <p className={cl.teacherName}>{name}</p>
      <div className={cl.infoContainer}>
        <span className={cl.label}>{"18+"}</span>
        <span className={cl.label}>{"B2"}</span>
      </div>
      <div className={cl.controls}>
        <Button
          style={{ pointerEvents: "none", padding: "12px" }}
          icon={<IconStar />}
          variant={"purple"}
          text={teacher.rating ?? 0}
        />
        <Button
          style={{
            pointerEvents: "none",
            padding: "12px",
            textTransform: "lowercase",
          }}
          variant={"grey"}
          text={`${teacher.amount ?? 0} ${t("exercises.lessons")}`}
        />
      </div>
    </div>
  );
};

export default observer(TeacherCard);
