import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import studentsStore from "../../store/studentsStore";
import { Input, ToggleButton, Toolbar } from "../../../../teacherComponents";
import { LanguageLevels } from "../../../../data/common";

const StudentSearchBar = () => {
  const { t } = useTranslation();

  const { difficulty, studentSearch } = studentsStore;

  return (
      <Toolbar>
        <Input
          search
          placeholder={t("library.search")}
          value={studentSearch}
          onChange={studentsStore.setStudentSearch}
        />
        <ToggleButton
          nullable
          options={LanguageLevels}
          selectedOption={difficulty}
          setOption={studentsStore.setDifficulty}
          variant={"round"}
        />
      </Toolbar>
  );
};

export default observer(StudentSearchBar);
