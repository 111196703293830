import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { v4 as uuid } from "uuid";
import MyAddButton from "../../../../../../../../components/UI/MyAddButton/MyAddButton";
import MyAdjustableTextarea from "../../../../../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import MyInputLabel from "../../../../../../../../components/UI/MyInputLabel/MyInputLabel";
import { QuestionsLabel } from "../../../Preview/components";
import cl from "./WordsOrderEditor.module.css";

const WordsOrderEditor = ({ exerciseData, onChange }) => {
  const { t } = useTranslation();
  const sentences = exerciseData.sentences || [];

  const [isAddDisabled, setIsAddDisabled] = useState(false);

  const handleAddSentence = () => {
    onChange({
      sentences: [...sentences, { id: uuid(), text: "" }],
    });
  };

  const handleUpdateSentence = (id, text) => {
    onChange({
      sentences: sentences.map((s) => (s.id === id ? { ...s, text } : s)),
    });
  };

  const deleteEducatorSentence = (id) => {
    const newSentences = sentences.filter((s) => s.id !== id);
    if (newSentences.length === 0) {
      newSentences.push([{ id: uuid(), text: "" }]);
    }
    onChange({
      sentences: newSentences,
    });
  };

  useEffect(() => {
    if (!sentences.length) {
      setIsAddDisabled(false);
      return;
    }
    if (sentences[sentences.length - 1]) {
      setIsAddDisabled(!sentences[sentences.length - 1].text.length);
    }
  }, [sentences]);

  return (
    <div className={cl.wordsOrderEditor}>
      <QuestionsLabel label={"edit_sentences"} />

      {sentences.length ? (
        <div className={cl.sentences}>
          {sentences.map((s) => (
            <div className={cl.sentenceCont} key={s.id}>
              <MyAdjustableTextarea
                className={cl.sentence}
                placeholder={t(`exercises.add_sentence`)}
                value={s.text}
                onChange={(text) => handleUpdateSentence(s.id, text)}
                onDelete={() => deleteEducatorSentence(s.id)}
                isFocus={!s.text.length}
                mode={"sentence"}
              />
            </div>
          ))}
        </div>
      ) : (
        <MyInputLabel text={t("exercises.no_sentences")} />
      )}
      <MyAddButton
        text={t(
          sentences.length
            ? "exercises.new_sentence"
            : "exercises.first_sentence"
        )}
        onClick={handleAddSentence}
        isDisabled={isAddDisabled}
      />
    </div>
  );
};

export default observer(WordsOrderEditor);
