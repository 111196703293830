import React from "react";
import { useTranslation } from "react-i18next";
import { ContentSearchBar } from "./components/index.js";
import {
  DictionaryView,
  ExercisesView,
  SituationsView,
  TrackView,
} from "./views/index.js";
import { ViewTitle } from "../../teacherComponents/index.js";
import { Chapters } from "../TeacherLessons/data/constants.js";
import { ReactComponent as IconDictionary } from "../../assets/svg/lessons-dictionary.svg";
import { ReactComponent as IconExercise } from "../../assets/svg/lessons-exercise.svg";
import { ReactComponent as IconSituation } from "../../assets/svg/lessons-situation.svg";
import { ReactComponent as IconTrack } from "../../assets/svg/lessons-track.svg";

const TeacherContent = ({ type }) => {
  const { t } = useTranslation();

  const iconMap = {
    [Chapters.Track]: <IconTrack />,
    [Chapters.Situation]: <IconSituation />,
    [Chapters.Exercise]: <IconExercise />,
    [Chapters.Dictionary]: <IconDictionary />,
  };

  return (
    <>
      <ViewTitle text={t(`exercises.${type}_view`)} icon={iconMap[type]} />
      <ContentSearchBar />
      <TrackView isActive={type===Chapters.Track} />
      <SituationsView />
      <DictionaryView />
      <ExercisesView />
    </>
  );
};

export default TeacherContent;
