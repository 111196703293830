import React from "react";
import { useTranslation } from "react-i18next";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import cl from "./AddChapterModal.module.css";
import { CloseButton } from "../../../../teacherComponents";

const AddChapterModal = ({ visible, setVisible, type, children }) => {
  const { t } = useTranslation();

  return (
    <MyModal className={cl.modal} visible={visible} setVisible={setVisible}>
      <div className={cl.modalHeader}>
        <p className={cl.title}> {t(`exercises.select_${type}`)}</p>
        <CloseButton onClick={() => setVisible(false)} />
      </div>
      {children}
    </MyModal>
  );
};

export default AddChapterModal;
