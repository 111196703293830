import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { DragDropContext } from "@hello-pangea/dnd";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useFetching } from "../../hooks/useFetching";
import ExerciseService from "../../api/ExerciseService";
import exercisesStore from "../TeacherContent/store/exercisesStore";
import lessonsStore from "./store/lessonsStore";
import teachersStore from "../Teachers/store/teachersStore";
import {
  AddTheme,
  Theme,
  EmptyTeacherLessons,
  LessonSearchBar,
} from "./components";
import { Loader } from "../../UI";
import { filterThemes } from "./helpers";
import { Views } from "./data/constants";
import cl from "./TeacherLessons.module.css";

const TeacherLessons = () => {
  const kanbanRef = useRef(null);

  const { i18n } = useTranslation();
  const lang = i18n.language;

  const { currentTeacher } = teachersStore;

  const {
    searchString,
    age,
    difficulty,
    filterByStudent,
    themes,
    teacherThemes,
    isAddLesson,
    currentStudentLessons,
  } = lessonsStore;

  const { currentStudent, view } = exercisesStore;

  const [filteredThemes, setFilteredThemes] = useState(themes);
  const [filteredTeacherThemes, setFilteredTeacherThemes] =
    useState(teacherThemes);

  const isTeacher = view === Views.Teachers && currentTeacher;

  const [getLessons, lessonsLoading] = useFetching(async () => {
    const { data } = await ExerciseService.getSavedLessons({
      offset: 0,
      limit: 100,
      lang,
    });

    if (data && data.items) {
      const lessons = await Promise.all(
        data.items.map(async (item) => {
          const lessonData = await ExerciseService.getLesson({ id: item.id });
          return { ...item, chapters: lessonData.data.chapters };
        })
      );
      syncLessons(lessons);
    }
  });

  const [getTeacherLessons, teacherLessonsLoading] = useFetching(async () => {
    if (!currentTeacher || !currentTeacher.id) return;
    const { data } = await ExerciseService.getTeacherLessons({
      id: currentTeacher.id,
    });
    if (data && data.items) {
      const teacherLessons = await Promise.all(
        data.items.map(async (item) => {
          const lessonData = await ExerciseService.getLesson({ id: item.id });
          return { ...item, chapters: lessonData.data.chapters };
        })
      );
      syncTeacherLessons(teacherLessons);
    }
  });

  const [updateLesson] = useFetching(async ({ id, topic }) => {
    await ExerciseService.updateLesson({ id, topic });
  });

  const parseLessonsIntoThemes = (lessons) => {
    const themeTitles = Array.from(new Set(lessons.map((l) => l.topic)));
    const themes = themeTitles.map((t) => ({
      id: uuid(),
      title: t,
      lessons: lessons.filter((l) => l.topic === t),
    }));
    return { themes };
  };

  const syncLessons = (lessons) => {
    const { themes } = parseLessonsIntoThemes(lessons);
    lessonsStore.setThemes(themes);
  };

  const syncTeacherLessons = (lessons) => {
    const { themes } = parseLessonsIntoThemes(lessons);
    lessonsStore.setTeacherThemes(themes);
  };

  const handleLessonDrag = async (result) => {
    const { destination, draggableId, source } = result;

    if (!source || !destination) return;

    const sourceTheme = themes.find((t) => t.id === source.droppableId);
    const destinationTheme = themes.find(
      (t) => t.id === destination.droppableId
    );

    if (!sourceTheme || !destinationTheme) return;

    if (sourceTheme.id === destinationTheme.id) {
      const newLessons = [...sourceTheme.lessons];
      const [movedLesson] = newLessons.splice(source.index, 1);
      newLessons.splice(destination.index, 0, movedLesson);
      lessonsStore.setLessons(sourceTheme.id, newLessons);
      return;
    }

    const movedLesson = sourceTheme.lessons.find((l) => l.id === draggableId);
    updateLesson({ id: movedLesson.id, topic: destinationTheme.title });

    lessonsStore.setLessons(
      sourceTheme.id,
      sourceTheme.lessons.filter((l) => l.id !== draggableId)
    );

    const newLessons = [...destinationTheme.lessons];
    newLessons.splice(destination.index, 0, movedLesson);
    lessonsStore.setLessons(destinationTheme.id, newLessons);
  };

  useEffect(() => {
    const filtered = filterThemes(themes, {
      age,
      difficulty,
      selected: filterByStudent ? currentStudentLessons : null,
      searchString,
    });
    setFilteredThemes(filtered);
  }, [
    age,
    difficulty,
    searchString,
    currentStudentLessons,
    filterByStudent,
    themes,
  ]);

  useEffect(() => {
    const filtered = filterThemes(teacherThemes, {
      age,
      difficulty,
      searchString,
    });
    setFilteredTeacherThemes(filtered);
  }, [age, difficulty, searchString, teacherThemes]);

  useEffect(() => {
    currentTeacher ? getTeacherLessons() : getLessons();
    return () => {
      exercisesStore.setCurrentStudent(null);
    };
  }, [currentTeacher]);

  useEffect(() => {
    if (isAddLesson && kanbanRef.current) {
      kanbanRef.current.scrollLeft = kanbanRef.current.scrollWidth;
    }
  }, [isAddLesson]);

  useEffect(() => {
    lessonsStore.setDifficulty("");
    lessonsStore.setAge("");
    lessonsStore.setSearchString("");
    lessonsStore.setFilterByStudent(false);
  }, [currentStudent]);

  return (
    <>
      {(isTeacher ? teacherLessonsLoading : lessonsLoading) ? (
        <Loader style={{ margin: "auto" }} />
      ) : (
        <>
          <LessonSearchBar />
          <DragDropContext onDragEnd={handleLessonDrag}>
            <div className={cl.kanban} ref={kanbanRef}>
              {(isTeacher ? filteredTeacherThemes : filteredThemes)?.map(
                (theme) => (
                  <Theme key={theme.id} themeObj={theme} />
                )
              )}
              {!isTeacher && <AddTheme />}
              {!(isTeacher ? filteredTeacherThemes : filteredThemes).length && (
                <EmptyTeacherLessons />
              )}
            </div>
          </DragDropContext>
        </>
      )}
    </>
  );
};

export default observer(TeacherLessons);
