import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { v4 as uuid } from "uuid";
import MyAddButton from "../../../../../../../../components/UI/MyAddButton/MyAddButton";
import MyAdjustableTextarea from "../../../../../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import MyInputLabel from "../../../../../../../../components/UI/MyInputLabel/MyInputLabel";
import cl from "./QuestionsEditor.module.css";
import { QuestionsLabel } from "../../../Preview/components";

const QuestionsEditor = ({ exerciseData, onChange }) => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState([]);

  const [isAddDisabled, setIsAddDisabled] = useState(false);

  const handleAddSentence = () => {
    onChange({
      questions: [...questions, { id: uuid(), question: "" }],
    });
  };

  const handleUpdateSentence = (id, question) => {
    onChange({
      questions: questions.map((s) => (s.id === id ? { ...s, question } : s)),
    });
  };

  const deleteEducatorSentence = (id) => {
    const newQuestions = questions.filter((s) => s.id !== id);
    if (newQuestions.length === 0) {
      newQuestions.push([{ id: uuid(), question: "" }]);
    }
    onChange({
      questions: newQuestions,
    });
  };

  useEffect(() => {
    if (!questions.length) {
      setIsAddDisabled(false);
      return;
    }
    if (questions[questions.length - 1]) {
      setIsAddDisabled(!questions[questions.length - 1].question.length);
    }
  }, [questions]);

  useEffect(() => {
    if (exerciseData.questions?.length) {
      setQuestions(exerciseData.questions);
    }
  }, [exerciseData]);

  return (
    <div className={cl.wordsOrderEditor}>
      {questions.length ? (
        <div className={cl.questions}>
          <QuestionsLabel label={"edit_questions"} />
          {questions.map((s, i) => (
            <div className={cl.questionCont} key={s.id}>
              <p className={cl.questionNumber}>{`${i + 1}.`}</p>
              <MyAdjustableTextarea
                className={cl.question}
                placeholder={t("exercises.add_question")}
                value={s.question}
                onChange={(question) => handleUpdateSentence(s.id, question)}
                onDelete={() => deleteEducatorSentence(s.id)}
                isFocus={!s.question?.length}
                mode={"sentence"}
              />
            </div>
          ))}
        </div>
      ) : (
        <MyInputLabel text={t("exercises.no_questions")} />
      )}
      <MyAddButton
        text={t(
          questions.length
            ? "exercises.new_question"
            : "exercises.first_question"
        )}
        onClick={handleAddSentence}
        isDisabled={isAddDisabled}
      />
    </div>
  );
};

export default observer(QuestionsEditor);
