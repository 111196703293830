import React from "react";
import { useTranslation } from "react-i18next";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import { Button, CloseButton } from "../../../../teacherComponents";
import cl from "./DeleteConfirmation.module.css";

const DeleteConfirmation = ({ visible, title, setVisible, onDelete }) => {
  const { t } = useTranslation();

  const closeModal = () => setVisible(false);

  return (
    <MyModal
      className={cl.confirmModal}
      visible={visible}
      setVisible={setVisible}
    >
      <p className={cl.title}>
        {title}
      </p>
      <p className={cl.subtitle}>
        {t(`exercises.delete_lesson_confirmation_subtitle`)}
      </p>
      <div className={cl.buttons}>
        <Button text={t("buttons.delete")} variant={"red"} onClick={onDelete} />
        <Button
          text={t("buttons.cancel")}
          variant={"dark"}
          onClick={closeModal}
        />
      </div>
      <CloseButton onClick={closeModal} />
    </MyModal>
  );
};

export default DeleteConfirmation;
