import React from "react";
import DemoPageLoader from "../../components/UI/DemoPageLoader/DemoPageLoader";
import cl from "./PrimaryButton.module.css";

const PrimaryButton = ({
  variant,
  text,
  icon,
  onClick,
  isDisabled,
  isLoading,
  style,
}) => {
  const classNames = [cl.button];

  switch (variant) {
    case "dark":
      classNames.push(cl.dark);
      break;
    case "green":
      classNames.push(cl.green);
      break;
    case "red":
    case "error":
      classNames.push(cl.red);
      break;
    case "magenta":
      classNames.push(cl.magenta);
      break;
    default:
      break;
  }

  return (
    <button
      disabled={isDisabled}
      style={{ ...style, color: isLoading && "transparent" }}
      className={classNames.join(" ")}
      onClick={onClick}
    >
      {icon && <div className={cl.icon}>{icon}</div>}
      {text}
      {isLoading && (
        <DemoPageLoader
          size={style?.height ? style.height - 6 : 24}
          color={variant === "dark" ? "#ffffff" : "#cbcbcb"}
        />
      )}
    </button>
  );
};

export default PrimaryButton;
