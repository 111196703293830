import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../../../hooks/useFetching";
import ProgressService from "../../../../../../api/ProgressService";
import MyModal from "../../../../../../components/UI/MyModal/MyModal";
import { PrimaryButton } from "../../../../../../UI";
import cl from "./GenerateWordsCategoryModal.module.css";

const GenerateWordsCategoryModal = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  
  return (
    <MyModal visible={visible} setVisible={setVisible}>
      <div></div>
    </MyModal>
  );
};

export default GenerateWordsCategoryModal;
