import { observer } from "mobx-react-lite";
import React, { useEffect, useCallback, useRef, useState } from "react";
import exercisesPlayer from "../../../../store/exercisesPlayer";
import toHoursAndMinutes from "../../../../../../utils/toHoursAndMinutes";
import getTopCenterAndOffset from "../../../../../../utils/getTopCenterAndOffset";
import { ReactComponent as IconGoNext } from "../../../../../../assets/img/icon-jump_next.svg";
import { ReactComponent as IconGoPrev } from "../../../../../../assets/img/icon-jump_prev.svg";
import { ReactComponent as IconPlay } from "../../../../../../assets/img/icon-play.svg";
import { ReactComponent as IconPause } from "../../../../../../assets/img/icon-pause.svg";
import cl from "./ExercisePlayerControls.module.css";

const TICKS_IN_SECOND = 10000000;

const ExercisePlayerControls = ({ mode, isPreview, controls = [] }) => {
  const savedRange = exercisesPlayer.mediaRange;

  const min = 0;
  const max = exercisesPlayer.trackLength;
  const trackLength = isPreview
    ? savedRange[1] - savedRange[0]
    : exercisesPlayer.trackLength;
  const [minVal, setMinVal] = useState(savedRange[0]);
  const [maxVal, setMaxVal] = useState(savedRange[1]);
  const [isEnded, setIsEnded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);
  const getPercent = useCallback(
    (value) => ((value - min) / (max - min)) * 100,
    [min, max]
  );

  useEffect(() => {
    setIsDisabled(!exercisesPlayer.isLoaded);
  }, [exercisesPlayer.isLoaded]);

  useEffect(() => {
    const defaultMin = savedRange?.length ? savedRange[0] : min;
    const defaultMax = savedRange?.length ? savedRange[1] : max;
    setMinVal(defaultMin);
    setMaxVal(defaultMax);
    minValRef.current = defaultMin;
    maxValRef.current = defaultMax;
    exercisesPlayer.setCurrentTime(exercisesPlayer.currentRange[0]);
  }, [savedRange, max]);

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);
    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
    if (!isPreview) {
      exercisesPlayer.handleChangeMediaTime(minVal);
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);
    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
    if (isEnded) {
      exercisesPlayer.setCurrentTime(minVal);
    }
    if (maxVal < exercisesPlayer.currentTime) {
      exercisesPlayer.handleChangeMediaTime(minVal);
    }
  }, [maxVal, getPercent]);

  useEffect(() => {
    if (!isPreview) {
      exercisesPlayer.setCurrentRange([minVal, maxVal]);
    }
  }, [minVal, maxVal]);

  useEffect(() => {
    if (exercisesPlayer.currentTime >= maxVal && exercisesPlayer.isPlaying) {
      exercisesPlayer.handlePlayPauseClick();
      setIsEnded(true);
    }
  }, [exercisesPlayer.currentTime]);

  const handleTracklineClick = (e) => {
    const clientX = e.clientX;
    const rect = getTopCenterAndOffset(e.target);
    const clickPercent = ((clientX - rect.left) / rect.width) * 100;
    const newTime = minVal + ((maxVal - minVal) / 100) * clickPercent;
    exercisesPlayer.handleChangeMediaTime(newTime);
  };

  const handleToggleVideo = () => {
    if (isEnded) {
      exercisesPlayer.handleChangeMediaTime(minVal);
      setIsEnded(false);
    }
    exercisesPlayer.handlePlayPauseClick();
  };

  const handleRewindClick = (direction) => {
    if (direction === "back") {
      let newTime = exercisesPlayer.currentTime - 5 * TICKS_IN_SECOND;
      newTime = newTime > minVal ? newTime : minVal;
      exercisesPlayer.handleChangeMediaTime(newTime);
      if (!exercisesPlayer.isPlaying) {
        exercisesPlayer.handlePlayPauseClick();
      }
    }
    if (direction === "forward") {
      let newTime = exercisesPlayer.currentTime + 5 * TICKS_IN_SECOND;
      newTime = newTime < maxVal ? newTime : maxVal;
      exercisesPlayer.handleChangeMediaTime(newTime);
    }
  };

  return (
    <div
      className={`${cl.playerCont} ${isDisabled && cl.disabled} ${cl[mode]} ${
        cl[isPreview ? "preview" : "main"]
      } `}
    >
      {!isPreview ? (
        <div className={cl.trackCont}>
          <span className={cl.timeItem}>
            {toHoursAndMinutes(
              Math.round(exercisesPlayer.currentTime / TICKS_IN_SECOND) || 0
            )}
          </span>
          <div className={cl.sliderCont}>
            {!isDisabled && (
              <>
                <input
                  type="range"
                  min={min}
                  max={max}
                  value={minVal}
                  onChange={(event) => {
                    if (!isPreview) {
                      const value = Math.min(
                        Number(event.target.value),
                        maxVal - 1
                      );
                      setMinVal(value);
                      minValRef.current = value;
                    }
                  }}
                  className={`${cl.thumb} ${cl.left} ${
                    cl[isPreview ? "preview" : "main"]
                  } ${isDisabled && cl.disabled}`}
                  style={{ zIndex: minVal > max - 100 ? 2 : 1 }}
                />
                <input
                  type="range"
                  min={min}
                  max={max}
                  value={maxVal}
                  onChange={(event) => {
                    if (!isPreview) {
                      const value = Math.max(
                        Number(event.target.value),
                        minVal + 1
                      );
                      setMaxVal(value);
                      maxValRef.current = value;
                    }
                  }}
                  className={`${cl.thumb} ${cl.right} ${
                    cl[isPreview ? "preview" : "main"]
                  } ${isDisabled && cl.disabled}`}
                  style={{ zIndex: minVal > max - 100 ? 1 : 2 }}
                />
              </>
            )}
            <div className={cl.slider}>
              <div
                className={`${cl.leftValue} ${
                  cl[isPreview ? "preview" : "main"]
                } ${isDisabled && cl.disabled}`}
                style={{
                  left: `calc(${(minVal / trackLength) * 100}% - 15px)`,
                }}
              >
                {toHoursAndMinutes(Math.round(minVal / TICKS_IN_SECOND))}
              </div>
              <div
                className={`${cl.rightValue} ${
                  cl[isPreview ? "preview" : "main"]
                } ${isDisabled && cl.disabled}`}
                style={{
                  right: `calc(${100 - (maxVal / trackLength) * 100}% - 15px)`,
                }}
              >
                {toHoursAndMinutes(Math.round(maxVal / TICKS_IN_SECOND))}
              </div>
              <div className={cl.sliderTrack} />
              <div
                ref={range}
                className={`${cl.sliderRange} ${
                  cl[isPreview ? "preview" : "main"]
                } ${isDisabled && cl.disabled}`}
                onClick={handleTracklineClick}
              />
              <div
                className={cl.trackProgress}
                style={{
                  left: `${(minVal / trackLength) * 100}%`,
                  width: `${(
                    ((exercisesPlayer.currentTime - minVal) / trackLength) *
                    100
                  ).toFixed(1)}%`,
                }}
              ></div>
            </div>
          </div>
          <span className={`${cl.timeItem} ${cl.right}`}>
            {toHoursAndMinutes(Math.round(trackLength / TICKS_IN_SECOND) || 0)}
          </span>
        </div>
      ) : (
        <div className={`${cl.trackCont} ${cl.preview}`}>
          <span className={cl.timeItem}>
            {toHoursAndMinutes(
              Math.round(
                (exercisesPlayer.currentTime - savedRange[0]) / TICKS_IN_SECOND
              ) || 0
            )}
          </span>
          <div className={cl.slider}>
            <div
              className={cl.sliderTrackPreview}
              onClick={handleTracklineClick}
            />
            <div className={cl.sliderRange} />
            <div
              className={cl.trackProgress}
              style={{
                left: 0,
                width: `${(
                  ((exercisesPlayer.currentTime - minVal) / trackLength) *
                  100
                ).toFixed(1)}%`,
              }}
            ></div>
          </div>
          <span className={`${cl.timeItem} ${cl.right}`}>
            {toHoursAndMinutes(Math.round(trackLength / TICKS_IN_SECOND) || 0)}
          </span>
        </div>
      )}
      <div className={cl.controlsCont}>
        {controls[0]}
          <button
            className={cl.controlBtnLeft}
            onClick={() => handleRewindClick("back")}
          >
            <IconGoPrev />
          </button>
        <button className={cl.playBtn} onClick={handleToggleVideo}>
          {exercisesPlayer.isPlaying ? (
            <IconPause style={{ width: 50, height: 50 }} />
          ) : (
            <IconPlay style={{ width: 50, height: 50 }} />
          )}
        </button>
          <button
            className={cl.controlBtnRight}
            onClick={() => handleRewindClick("forward")}
          >
            <IconGoNext />
          </button>
        {controls[1]}
        {controls[2]}
      </div>
    </div>
  );
};

export default observer(ExercisePlayerControls);
