import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import UserService from "../../api/UserService";
import { useFetching } from "../../hooks/useFetching";
import { AuthContext, InterfaceContext } from "../../App";
import Loader from "../../components/UI/Loader/Loader";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import {
  PrimaryButton,
  LabeledInput,
  LabeledToggleButton,
  LabeledSelect,
} from "../../UI";
import { Roles } from "../../data/common";
import cl from "./Profile.module.css";

const Genders = {
  Male: "male",
  Female: "female",
};

const Profile = () => {
  const { t, i18n } = useTranslation();

  const { auth } = useContext(AuthContext);
  const { appInterface } = useContext(InterfaceContext);

  const { availableLanguages } = appInterface;
  const { user } = auth;
  const { role } = user;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [sex, setSex] = useState("");

  const [updateUser, isLoading] = useFetching(async () => {
    const { id } = user;
    const res = await UserService.updateUser({ id, name, sex });
    if (res && res.status === 204) {
      auth.setUser({ ...user, name, sex });
    }
  });

  useEffect(() => {
    setName(user.name ?? "");
    setEmail(user.email ?? "");
    setSex(user.sex ?? "");
  }, [user]);

  const hasChanged = () => {
    if (name && name !== user.name) return true;
    if (sex && sex !== user.sex) return true;
    return false;
  };

  const logout = () => {
    auth.logout();
  };

  return (
    <PageWrapper style={{ overflowY: "auto", position: "relative" }}>
      {isLoading && <Loader />}
      <div className={cl.profile}>
        <LabeledInput
          label={t("profile.name")}
          value={name}
          onChange={setName}
        />
        <LabeledInput label={t("email")} value={email} disabled={true} />
        <div className={cl.horizontalCont}>
          <LabeledToggleButton
            style={{ justifyContent: "flex-start", borderRadius: 20 }}
            label={t("profile.gender")}
            selectedOption={sex}
            options={Object.values(Genders)}
            setOption={setSex}
            withTranslations={"profile"}
          />
          {role === Roles.Teacher && (
            <LabeledSelect
              label={t("situations.language")}
              value={i18n.language}
              options={availableLanguages}
              onChange={appInterface.setLanguage}
            />
          )}
        </div>
        <LabeledInput label={t("users.role")} value={role} disabled={true} />
        <PrimaryButton variant={"light"} text={"Logout"} onClick={logout} />
        {hasChanged() && (
          <PrimaryButton
            text={t("buttons.save_changes")}
            variant={"dark"}
            onClick={updateUser}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default observer(Profile);
