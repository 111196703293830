import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../../../hooks/useFetching";
import SituationsService from "../../../../../../api/SituationsService";
import {
  Input,
  ToggleButton,
  Toolbar,
} from "../../../../../../teacherComponents";
import { LanguageLevels } from "../../../../../../data/common";
import Situation from "../Situation/Situation";
import { FilterModes } from "../../../../data/constants";
import {
  AddChapterModal,
  ChapterPlug,
  ChapterPreviewContainer,
  ChaptersScrollableList,
} from "../../../../components";
import { Chapters } from "../../../../../TeacherLessons/data/constants";
import cl from "./SelectSituationModal.module.css";
import ProgressService from "../../../../../../api/ProgressService";

const SelectSituationModal = ({
  visible,
  setVisible,
  onClick,
  withCategorySelector,
  alreadyAdded = [],
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [mode, setMode] = useState(
    withCategorySelector ? FilterModes.Teacher : FilterModes.All
  );
  const [situations, setSituations] = useState([]);
  const [filteredSituations, setFilteredSituations] = useState([]);
  const [difficulty, setDifficulty] = useState();
  const [searchString, setSearchString] = useState();
  const [currentSituation, setCurrentSituation] = useState();

  const [getAllSituations, allLoading] = useFetching(async () => {
    if (!visible) return;
    const { data } = await SituationsService.getAvailableCategories({
      language: lang.toUpperCase(),
      level: difficulty,
    });

    const allSituations = data.map((d) => d.situations).flat();
    const withoutExisting = allSituations.filter(
      (e) => !alreadyAdded.some((a) => a.id === e.id)
    );
    setSituations(withoutExisting);

    if (!currentSituation) setCurrentSituation(withoutExisting[0]);
  });

  const [getFavSituations, favLoading] = useFetching(async () => {
    const { data } = await ProgressService.getSituations({
      lang,
    });
    const ids = data.items.map((s) => s.situationId);

    const { data: categories } = await SituationsService.getAvailableCategories(
      {
        language: lang.toUpperCase(),
      }
    );

    const situations = categories
      .map((c) => c.situations)
      .flat()
      .filter((s) => ids.includes(s.id));

    setSituations(situations);
    if (!currentSituation) setCurrentSituation(situations[0]);
  });

  const handleSituationSelect = () => {
    if (!currentSituation) return;

    onClick(currentSituation.id);
    setVisible(false);
  };

  useEffect(() => {
    mode === FilterModes.All ? getAllSituations() : getFavSituations();
  }, [mode, visible, lang, difficulty]);

  useEffect(() => {
    setCurrentSituation();
  }, [visible]);

  useEffect(() => {
    if (!searchString) {
      setFilteredSituations(situations);
    } else {
      setFilteredSituations(
        situations.filter((s) =>
          s.title.toLowerCase().includes(searchString.toLowerCase())
        )
      );
    }
  }, [searchString, situations]);

  return (
    <AddChapterModal
      visible={visible}
      setVisible={setVisible}
      type={Chapters.Situation}
    >
      <Toolbar>
        <ToggleButton
          selectedOption={difficulty}
          setOption={setDifficulty}
          options={LanguageLevels}
          nullable
        />
        {withCategorySelector && (
          <ToggleButton
            selectedOption={mode}
            setOption={setMode}
            options={Object.values(FilterModes)}
            withTranslations={"exercises"}
          />
        )}
        <Input
          variant={"outlinedSmall"}
          placeholder={t("library.search")}
          value={searchString}
          onChange={setSearchString}
        />
      </Toolbar>
      <div className={cl.situationsContainer}>
        <ChaptersScrollableList
          type={Chapters.Situation}
          isLoading={allLoading || favLoading}
          chapters={filteredSituations}
          current={currentSituation}
          setCurrent={setCurrentSituation}
          onScroll={() => {}}
          onSelect={handleSituationSelect}
        />
        {currentSituation ? (
          <ChapterPreviewContainer>
            <Situation situation={currentSituation} />
          </ChapterPreviewContainer>
        ) : (
          <ChapterPlug />
        )}
      </div>
    </AddChapterModal>
  );
};

export default SelectSituationModal;
