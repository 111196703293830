import { makeAutoObservable } from "mobx";
import i18n from "i18next";
import { interfaceLangs } from "../data/interfaceLangs";
import LanguagesService from "../api/LanguagesService";

const DEFAULT_LANG = "en";

export default class Interface {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true, deep: true });
    const broswerLang = navigator?.languages[0]?.slice(0, 2);
    const savedLanguage = localStorage.getItem("i18nextLng");

    const currentLanguage = savedLanguage || broswerLang || DEFAULT_LANG;

    this.getAllLanguages();
    this.getAvailableLanguages();
    this.setLanguage(currentLanguage);
  }

  setLanguage(lang) {
    if (!lang) return;
    if (typeof lang === "object") lang = lang.value;
    for (let i = 0; i < interfaceLangs.length; i++) {
      const iLang = interfaceLangs[i];
      if (iLang.value === lang) {
        i18n.changeLanguage(iLang.value);
        break;
      }
    }
    this.language = lang;
  }

  async getAllLanguages() {
    try {
      const { data } = await LanguagesService.getAllLanguages({ ready: null });
      if (!data.map) return;
      this.languages = data
        .map((lObj) => ({
          value: lObj.code.toLowerCase(),
          name: lObj.title,
        }))
        .sort((a, b) => a.value.localeCompare(b.value));
    } catch (e) {
      this.languages = [{ code: "en", title: "English" }]
        .map((lObj) => ({
          value: lObj.code.toLowerCase(),
          name: lObj.title,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  async getAvailableLanguages() {
    try {
      const { data } = await LanguagesService.getAllLanguages({ ready: true });
      if (!data.map) return;
      this.availableLanguages = data
        .map((lObj) => ({
          value: lObj.code.toLowerCase(),
          name: lObj.title,
        }))
        .sort((a, b) => a.value.localeCompare(b.value));
    } catch (e) {
      this.languages = [{ code: "en", title: "English" }]
        .map((lObj) => ({
          value: lObj.code.toLowerCase(),
          name: lObj.title,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  }
}
