import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../../../store/exercisesStore";
import {
  ExerciseIcon,
  ExerciseMediaIcon,
  MediaOptions,
  TextExerciseOptions,
} from "..";
import {
  DefaultExerciseOptions,
  DefaultMediaExerciseOptions,
  GrammarExerciseTypes,
  MediaExerciseTypes,
  MediaTypes,
} from "../../../../data/constants";
import MyModal from "../../../../../../components/UI/MyModal/MyModal";
import { CloseButton } from "../../../../../../teacherComponents";
import cl from "./GenerateExerciseModal.module.css";

const GenerateExerciseModal = ({ visible, setVisible }) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState({
    ...DefaultExerciseOptions,
  });

  const [mediaOptions, setMediaOptions] = useState({
    ...DefaultMediaExerciseOptions,
  });

  const handleOptionsTypeSelect = (type) => {
    setMediaOptions({ ...DefaultMediaExerciseOptions });
    setOptions({ ...options, type });
  };

  const handleMediaOptionsTypeSelect = (type, mediaType) => {
    setOptions({ ...DefaultExerciseOptions });
    setMediaOptions({ ...mediaOptions, type, mediaType });
  };

  const handleExerciseCreate = (data) => {
    exercisesStore.setChaptersModalType("");
    exercisesStore.setExercises([data, ...exercisesStore.exercises]);
    setTimeout(() => {
      exercisesStore.setCurrentExercise(data);
    }, 300);
  };

  const handleCloseButton = () => {
    if (!options.type && !mediaOptions.type) {
      setVisible(false);
    } else {
      setOptions(DefaultExerciseOptions);
      setMediaOptions(DefaultMediaExerciseOptions);
    }
  };

  const renderTitle = () => {
    if (options.type) {
      return <p className={cl.stepTitle}>{t(`exercises.${options.type}`)}</p>;
    }
    if (mediaOptions.type) {
      return (
        <p className={cl.stepTitle}>{t(`exercises.${mediaOptions.type}`)}</p>
      );
    }
  };

  const renderContent = () => {
    if (
      options.type &&
      Object.values(GrammarExerciseTypes).includes(options.type)
    ) {
      return (
        <TextExerciseOptions
          options={options}
          setOptions={setOptions}
          onCreate={handleExerciseCreate}
        />
      );
    }

    if (
      mediaOptions.type &&
      Object.values(MediaExerciseTypes).includes(mediaOptions.type)
    ) {
      return (
        <MediaOptions
          options={mediaOptions}
          setOptions={setMediaOptions}
          onCreate={handleExerciseCreate}
        />
      );
    }

    return (
      <div className={cl.exercisesContainer}>
        <div className={cl.textBlock}>
          <p>{t("exercises.grammar_exercises")}</p>
          <span>{t("exercises.grammar_exercises_desc")}</span>
        </div>
        <div className={cl.typesContainer}>
          {Object.values(GrammarExerciseTypes).map((type) => (
            <div
              className={cl.exerciseTypeCard}
              key={type}
              onClick={() => handleOptionsTypeSelect(type)}
            >
              <ExerciseIcon type={type} additionalClass={cl.modalIcon} />
              <ExerciseMediaIcon type={type} style={{ top: 10, right: 10 }} />
              <div className={cl.textBlock}>
                <p>{t(`exercises.${type}`)}</p>
                <span>{t(`exercises.${type}_desc`)}</span>
              </div>
            </div>
          ))}
        </div>
        <div className={cl.textBlock}>
          <p>{t("exercises.media_exercises")}</p>
          <span>{t("exercises.media_exercises_desc")}</span>
        </div>
        <div className={cl.typesContainer}>
          {Object.values(MediaExerciseTypes).map((type) => (
            <div
              className={cl.exerciseTypeCard}
              key={type}
              onClick={() =>
                handleMediaOptionsTypeSelect(type, MediaTypes.Video)
              }
            >
              <ExerciseIcon type={type} additionalClass={cl.modalIcon} />
              <ExerciseMediaIcon
                type={"video"}
                style={{ top: 10, right: 10 }}
              />
              <div className={cl.textBlock}>
                <p>{t(`exercises.${type}`)}</p>
                <span>{t(`exercises.${type}_desc`)}</span>
              </div>
            </div>
          ))}
        </div>

        <div className={cl.textBlock}>
          <p>{t("exercises.text_exercises")}</p>
          <span>{t("exercises.text_exercises_desc")}</span>
        </div>
        <div className={cl.typesContainer}>
          {Object.values(MediaExerciseTypes).map((type, i) => (
            <div
              className={cl.exerciseTypeCard}
              key={`${type}_${i}`}
              onClick={() =>
                handleMediaOptionsTypeSelect(type, MediaTypes.GeneratedText)
              }
            >
              <ExerciseIcon type={type} additionalClass={cl.modalIcon} />
              <ExerciseMediaIcon type={"text"} style={{ top: 10, right: 10 }} />
              <div className={cl.textBlock}>
                <p>{t(`exercises.${type}`)}</p>
                <span>{t(`exercises.${type}_text_desc`)}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    setOptions({ ...DefaultExerciseOptions, type: options.type });
    exercisesStore.setGeneratedText(null);
  }, [options.type]);

  useEffect(() => {
    setMediaOptions({
      ...DefaultMediaExerciseOptions,
      mediaType: mediaOptions.mediaType,
      type: mediaOptions.type,
    });
  }, [mediaOptions.type]);

  useEffect(() => {
    setOptions(DefaultExerciseOptions);
    setMediaOptions(DefaultMediaExerciseOptions);
  }, [visible]);

  return (
    <MyModal
      className={`${cl.createExercise} ${
        !options.type && !mediaOptions.type && cl.large
      }`}
      visible={visible}
      setVisible={setVisible}
    >
      {renderTitle()}
      {renderContent()}
      <CloseButton onClick={handleCloseButton} />
    </MyModal>
  );
};

export default observer(GenerateExerciseModal);
