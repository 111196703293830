import React, { useEffect, useState } from "react";
import { DragDropContext } from "@hello-pangea/dnd";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../../TeacherContent/store/exercisesStore.js";
import lessonsStore from "../../../TeacherLessons/store/lessonsStore.js";
import studentsStore from "../../store/studentsStore.js";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching.js";
import ProgressService from "../../../../api/ProgressService.js";
import { Loader } from "../../../../UI";
import { StudentTheme } from "..";
import { LanguageLevels } from "../../../../data/common.js";
import { StudentLessonStatuses } from "../../../TeacherLessons/data/constants.js";
import cl from "./StudentKanban.module.css";

const StudentKanban = ({ student }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { currentStudent } = exercisesStore;
  const { age, difficulty, searchString } = studentsStore;
  const { currentStudentLessons } = lessonsStore;

  const [themes, setThemes] = useState([]);
  const [filtered, setFilterd] = useState([]);

  const [getStudentChapters, isLoading] = useFetching(async () => {
    const userId = student.id;

    const { data: trackData } = await ProgressService.getStudentTracks({
      userId,
      lang,
    });

    const { data: exerciseData } = await ProgressService.getStudentExercises({
      userId,
      lang,
    });

    const { data: situationData } = await ProgressService.getStudentSituations({
      userId,
      lang,
    });

    const { data: wordData } = await ProgressService.getStudentDictionaries({
      userId,
      lang,
    });

    const { data: lessonsData } = await ProgressService.getStudentLessons({
      userId,
      lang,
    });

    studentsStore.setCurrentStudentChapters({
      situations: situationData?.items ?? [],
      tracks: trackData?.items ?? [],
      exercises: exerciseData?.items ?? [],
      dictionaries: wordData?.items ?? [],
      words: [],
    });
    if (!lessonsData?.items) return;
    const { items } = lessonsData;
    const unfinished = items.filter(
      (l) => l.status === StudentLessonStatuses.Unfinished
    );
    const postponed = items.filter(
      (l) => l.status === StudentLessonStatuses.Postponed
    );
    const done = items.filter((l) => l.status === StudentLessonStatuses.Done);
    setThemes([
      {
        id: StudentLessonStatuses.Postponed,
        title: t(`exercises.postponed`),
        lessons: postponed,
      },
      {
        id: StudentLessonStatuses.Unfinished,
        title: t(`exercises.in_process`),
        lessons: unfinished,
      },
      {
        id: StudentLessonStatuses.Done,
        title: t(`exercises.done`),
        lessons: done,
      },
    ]);
  });

  const [updateLesson] = useFetching(async ({ lessonId, status }) => {
    syncKanban({ lessonId, status });
    syncMainLessons({ lessonId, status });

    await ProgressService.evaluateLesson({
      studentId: student.id,
      lessonId,
      status,
    });
  });

  const syncKanban = ({ lessonId, status }) => {
    const lessonToMove = themes
      .flatMap((t) => t.lessons)
      .find((l) => l.lessonId === lessonId);

    if (!lessonToMove) {
      return;
    }

    setThemes(
      themes.map((t) => {
        if (t.id === status) {
          return {
            ...t,
            lessons: [...t.lessons, lessonToMove],
          };
        }

        if (t.lessons.some((l) => l.lessonId === lessonId)) {
          return {
            ...t,
            lessons: t.lessons.filter((l) => l.lessonId !== lessonId),
          };
        }

        return t;
      })
    );
  };

  const syncMainLessons = ({ lessonId, status }) => {
    if (!currentStudent || currentStudent.id !== student.id) return;
    if (!currentStudentLessons || !currentStudentLessons.length) return;

    const newLessons = currentStudentLessons.some(
      (l) => l.lessonId === lessonId
    )
      ? currentStudentLessons.map((l) =>
          l.lessonId === lessonId ? { ...l, status } : l
        )
      : [...currentStudentLessons, { lessonId, status }];

    lessonsStore.setCurrentStudentLessons(newLessons);
  };

  const handleLessonDrag = async (result) => {
    const { destination, draggableId, source } = result;
    if (!source || !destination) return;

    const sourceTheme = themes.find((t) => t.id === source.droppableId);
    const destinationTheme = themes.find(
      (t) => t.id === destination.droppableId
    );
    if (!sourceTheme || !destinationTheme) return;
    if (sourceTheme.id === destinationTheme.id) return;

    updateLesson({
      lessonId: draggableId,
      status: destinationTheme.id,
    });
  };

  useEffect(() => {
    getStudentChapters();
  }, [student]);

  useEffect(() => {
    const filteredBySearchString = searchString
      ? themes.filter(
          (t) =>
            t.title.toLowerCase().includes(searchString.toLowerCase()) ||
            t.lessons.some((l) =>
              l.title.toLowerCase().includes(searchString.toLowerCase())
            )
        )
      : themes;

    const filteredByLessonParams = filteredBySearchString.map((t) => {
      const themeMatchesSearch = t.title
        ?.toLowerCase()
        .includes(searchString.toLowerCase());

      const filteredLessons = t.lessons.filter((l) => {
        const matchesSearch = searchString
          ? l.title.toLowerCase().includes(searchString.toLowerCase())
          : true;
        const matchesAge = typeof age === "number" ? l.age === age : true;
        const matchesDifficulty = difficulty
          ? l.difficulty === LanguageLevels.indexOf(difficulty)
          : true;

        return (
          (themeMatchesSearch || matchesSearch) &&
          matchesAge &&
          matchesDifficulty
        );
      });

      return {
        ...t,
        lessons: filteredLessons,
      };
    });

    setFilterd(filteredByLessonParams);
  }, [age, difficulty, searchString, themes]);

  return (
    <DragDropContext onDragEnd={handleLessonDrag}>
      {isLoading ? (
        <Loader style={{ margin: "auto", height: "100%" }} />
      ) : (
        <div className={cl.kanban}>
          {filtered.map((t) => (
            <StudentTheme key={t.id} themeObj={t} />
          ))}
        </div>
      )}
    </DragDropContext>
  );
};

export default observer(StudentKanban);
