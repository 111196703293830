import React, { useEffect, useRef } from "react";
import cl from "./Range.module.css";

const Range = ({ value, min, step, max, onChange, style }) => {
  const rangeRef = useRef(null);

  useEffect(() => {
    const percentage = ((value - min) / (max - min)) * 100;
    if (rangeRef.current) {
      rangeRef.current.style.background = `linear-gradient(to right, #b770ff ${percentage}%, #d3d3d3 ${percentage}%)`;
    }
  }, [value, min, max]);

  return (
    <div className={cl.rangeContainer} style={style}>
      <span className={cl.label}>{min.toString()}</span>
      <input
        ref={rangeRef}
        className={cl.range}
        type="range"
        value={value}
        onChange={onChange}
        min={min}
        step={step}
        max={max}
      />
      <span className={cl.label}>{max.toString()}</span>
    </div>
  );
};

export default Range;
