import React from "react";
import cl from "./TeachersTutorial.module.css";

const TeachersTutorial = () => {
  return (
    <>
      <div className={cl.cont}>{"FAQ"}</div>
    </>
  );
};

export default TeachersTutorial;
