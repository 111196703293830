import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import shuffleArray from "../../../../../../../../utils/shuffleArray";
import cl from "./MultichoicePreview.module.css";
import QuestionsLabel from "../QuestionsLabel/QuestionsLabel";

const MultichoicePreview = ({ exerciseObj, results, showAnswers }) => {
  const { t } = useTranslation();

  const { sentences } = exerciseObj.data;

  const tooltipRef = useRef();

  const [words, setWords] = useState([]);
  const [dropdownAnswers, setDropdownAnswers] = useState([]);
  const [currentDropdown, setCurrentDropdown] = useState(null);

  const renderWord = (w) => {
    if (!w?.options) return w.word;

    const classNames = [cl.dropdown];
    if (currentDropdown === w.id) classNames.push(cl.selected);
    if (results || showAnswers) {
      classNames.push(cl.results);
      const isCorrect =
        dropdownAnswers.find((a) => a.word.id === w.id)?.answer === w.word;
      classNames.push(isCorrect ? cl.correct : cl.wrong);
    }

    return (
      <span
        key={w.id}
        className={classNames.join(" ")}
        onClick={results ? () => {} : () => handleWordClick(w.id)}
        ref={currentDropdown === w.id ? tooltipRef : null}
      >
        <span className={cl.dropdownIcon}></span>
        {dropdownAnswers.find((a) => a.word.id === w.id)?.answer}
        <div className={cl.dropdownTooltip}>
          <p className={cl.dropdownText}>{t("exercises.dropdown_title")}</p>
          <div className={cl.optionsCont}>
            {w?.options
              .filter((o) => o.length)
              .map((o, i) => (
                <span key={`${o}${i}`} onClick={() => handleOptionClick(w, o)}>
                  {o}
                </span>
              ))}
          </div>
        </div>
      </span>
    );
  };

  const handleWordClick = (id) => {
    setCurrentDropdown(currentDropdown === id ? null : id);
  };

  const handleOptionClick = (word, option) => {
    const newAnswers = dropdownAnswers.map((a) => {
      if (a.word.id === word.id) {
        return { ...a, answer: option };
      }
      return a;
    });
    setDropdownAnswers(newAnswers);
  };

  const displayAnswers = () => {
    setDropdownAnswers(
      dropdownAnswers.map((a) => ({
        ...a,
        answer: a.word.word,
      }))
    );
  };

  const resetAnswers = () => {
    if (dropdownAnswers.some((w) => w.answer)) {
      setDropdownAnswers(dropdownAnswers.map((w) => ({ ...w, answer: "" })));
    }
  };

  const handleClickOutside = (e) => {
    if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
      setCurrentDropdown(null);
    }
  };

  const getAnswerById = (userAnswers, id) => {
    for (let i = 0; i < userAnswers.length; i++) {
      if (userAnswers[i][id] !== undefined) {
        return userAnswers[i][id];
      }
    }
    return null;
  };

  useEffect(() => {
    if (results) return;
    showAnswers ? displayAnswers() : resetAnswers();
  }, [showAnswers]);

  useEffect(() => {
    const flatWords = sentences?.map((s) => s.words).flat() ?? [];
    const dropdownWords = flatWords
      .filter((w) => w?.options)
      .map((w) => ({ word: w, answer: "" }));
    setWords(
      flatWords.map((w) =>
        w?.options ? { ...w, options: shuffleArray(w?.options ?? []) } : w
      )
    );
    if (!results?.userAnswers) {
      setDropdownAnswers(dropdownWords);
      return;
    }
    const { userAnswers } = results;
    setDropdownAnswers(
      dropdownWords.map((a) => ({
        ...a,
        answer: getAnswerById(userAnswers, a.word.id),
      }))
    );
  }, [results]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  return (
    words.length !== 0 && (
      <>
        {results && <QuestionsLabel label={"student_answers"} />}
        <div className={cl.exerciseText}>
          {words.map((w) => (
            <span className={cl.wordContainer} key={w.id}>
              {renderWord(w)}
              {w.endChar && <span>{w.endChar}</span>}
            </span>
          ))}
        </div>
      </>
    )
  );
};

export default MultichoicePreview;
