export const Faces = [
  "😀",
  "😎",
  "😍",
  "🤗",
  "🤔",
  "🤨",
  "🙄",
  "😏",
  "😥",
  "🤐",
  "🥱",
  "😴",
  "😌",
  "😝",
  "🤤",
  "😒",
  "🤑",
  "😲",
  "😤",
  "😨",
  "🤯",
  "😬",
  "🥵",
  "🥶",
  "😳",
  "🤪",
  "😵",
  "😡",
  "😠",
  "🤬",
  "😷",
  "🤒",
  "🤕",
];
