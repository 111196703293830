const DefaultTitle = "Pony - Learn Language with YouTube";

const LanguageLevels = ["A1", "A2", "B1", "B2", "C1", "C2"];

const Roles = {
  Admin: "admin",
  User: "user",
  Teacher: "teacher",
  Guest: "guest",
};

const TicksInSecond = 10000000;


export { DefaultTitle, LanguageLevels, Roles, TicksInSecond };
