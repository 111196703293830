import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { auth } from "../../../../App";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import {
  Button,
  CloseButton,
  MicroButton,
} from "../../../../teacherComponents";
import { ReactComponent as IconAdd } from "../../../../assets/svg/lessons-add.svg";
import cl from "./AddStudentModal.module.css";

const AddStudentModal = () => {
  const { t } = useTranslation();

  const { user } = auth;

  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState(false);

  const joinUrl = `https://dev.thepony.app/follow-teacher?id=${user?.id}`;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(joinUrl)
      .then(() => {
        setMessage(t("exercises.add_student_alert"));
      })
      .catch((err) => {
        setMessage(`${t("exercises.add_student_error")}(${err})`);
      });
  };

  useEffect(() => {
    setMessage(false);
  }, [visible]);

  return (
    <>
      <MyModal
        visible={visible}
        setVisible={setVisible}
        className={cl.modalInner}
      >
        <p className={cl.title}>{t("exercises.add_student")}</p>
        <p className={cl.subtitle}>{t("exercises.add_student_description")}</p>
        <div className={cl.linkContainer} onClick={handleCopy}>
          <p className={cl.linkLabel}>{t("exercises.student_link")}</p>
          <p>{joinUrl}</p>
        </div>
        <Button
          style={{
            pointerEvents: message ? "none" : "all",
            width: "100%",
            marginTop: 20,
          }}
          variant={message ? "grey" : "dark"}
          onClick={handleCopy}
          text={message || t("buttons.copy_link")}
        />
        <CloseButton onClick={() => setVisible(false)} />
      </MyModal>
      <div className={cl.buttonContainer} onClick={() => setVisible(true)}>
        <MicroButton
          icon={<IconAdd />}
          variant={"grey"}
          size={"large"}
          isDisabled={!user?.id}
        />
        <p> {t("exercises.add_student")}</p>
      </div>
    </>
  );
};

export default observer(AddStudentModal);
