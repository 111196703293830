import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import teachersStore from "../../../Teachers/store/teachersStore";
import { Button } from "../../../../teacherComponents";
import { ReactComponent as IconEyes } from "../../../../assets/svg/lessons-eyes.svg";
import cl from "./EmptyTeacherLessons.module.css";

const EmptyTeacherLessons = () => {
  const { t } = useTranslation();
  const { currentTeacher } = teachersStore;
  if (!currentTeacher) return null;

  return (
    <div className={cl.emptyAlert}>
      <IconEyes />
      <p className={cl.alertTitle}>{t("exercises.empty_teacher_lessons")}</p>
      <div className={cl.buttons}>
        <Button
          variant={"grey"}
          text={t("buttons.back")}
          onClick={() => teachersStore.setCurrentTeacher(null)}
        />
      </div>
    </div>
  );
};

export default observer(EmptyTeacherLessons);
