import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Loader } from "../../../../UI";
import { ChapterCard } from "..";
import { ReactComponent as IconEyes } from "../../../../assets/svg/lessons-eyes.svg";
import cl from "./ChaptersScrollableList.module.css";

const ChaptersScrollableList = ({
  isLoading,
  chapters,
  type,
  current,
  setCurrent,
  onScroll,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cl.chaptersContainer}>
      {chapters.length ? (
        <div className={cl.list} onScroll={onScroll}>
          {chapters.map((chapter) => (
            <ChapterCard
              key={chapter.id}
              type={type}
              chapter={chapter}
              isActive={current?.id === chapter.id}
              setActive={setCurrent}
              isScrollable
              onSelect={onSelect}
            />
          ))}
        </div>
      ) : isLoading ? (
        <div className={cl.emptyAlert}>
          <Loader style={{ opacity: 0.2 }} />
        </div>
      ) : (
        <div className={cl.emptyAlert}>
          <IconEyes />
          <p className={cl.alertTitle}>
            {t(`exercises.empty_search_${type}_title`)}
          </p>
          <p className={cl.alertSubtitle}>
            {t(`exercises.empty_search_${type}_subtitle`)}
          </p>
        </div>
      )}
    </div>
  );
};

export default observer(ChaptersScrollableList);
