import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { StudentsSelect } from "../../../TeacherContent/components";
import lessonsStore from "../../store/lessonsStore";
import exercisesStore from "../../../TeacherContent/store/exercisesStore";
import { LanguageLevels } from "../../../../data/common";
import { AgeOptions } from "../../../TeacherContent/data/constants";
import {
  Button,
  ControlButton,
  Input,
  Select,
  Separator,
  ToggleButton,
  Toolbar,
} from "../../../../teacherComponents";
import { Views } from "../../data/constants";
import { ReactComponent as IconAdd } from "../../../../assets/svg/lessons-add.svg";
import teachersStore from "../../../Teachers/store/teachersStore";
import cl from "./LessonSearchBar.module.css";

const LessonSearchBar = () => {
  const { t } = useTranslation();
  const { age, collapseCards, difficulty, filterByStudent, searchString } =
    lessonsStore;
  const { currentTeacher } = teachersStore;
  const { view, currentStudent } = exercisesStore;

  const isTeacher = view === Views.Teachers && currentTeacher;

  const handleCollapseClick = () => {
    lessonsStore.toggleCollapseCards();
  };

  return (
    <div className={cl.searchContainer}>
      <Toolbar>
        {!isTeacher && (
          <>
            <Button
              variant={"white"}
              icon={<IconAdd className={cl.plusIcon} />}
              text={t("exercises.add_theme")}
              onClick={() => lessonsStore.setIsAddLesson(true)}
            />
            <Separator />
          </>
        )}
        <ToggleButton
          nullable
          options={LanguageLevels}
          selectedOption={difficulty}
          setOption={lessonsStore.setDifficulty}
          variant={"round"}
        />
        <Select
          variant={"white"}
          style={{ minWidth: 140 }}
          label={t("users.age")}
          value={age}
          options={
            typeof age === "number"
              ? [{ value: "", name: t("podcasts.all") }, ...AgeOptions]
              : AgeOptions
          }
          onChange={lessonsStore.setAge}
        />
        <Separator />
        <Input
          search
          placeholder={t("library.search")}
          value={searchString}
          onChange={lessonsStore.setSearchString}
        />
        <Separator />
        <ControlButton
          type={collapseCards ? "uncollapse" : "collapse"}
          onClick={handleCollapseClick}
        />
        <ControlButton
          type="help"
          onClick={() => {
            alert("this button has no functionality yet");
          }}
        />
      </Toolbar>
      {!isTeacher && (
        <>
          {currentStudent && (
            <Button
              style={{ marginTop: 8 }}
              variant={"purple"}
              text={t(
                filterByStudent
                  ? "exercises.show_all"
                  : "exercises.filter_by_student"
              )}
              onClick={lessonsStore.toggleFilterByStudent}
            />
          )}
          <StudentsSelect />
        </>
      )}
    </div>
  );
};

export default observer(LessonSearchBar);
