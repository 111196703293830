import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import exercisesStore from "../../../../store/exercisesStore";
import exercisesPlayer from "../../../../store/exercisesPlayer";
import { useFetching } from "../../../../../../hooks/useFetching";
import ExerciseService from "../../../../../../api/ExerciseService";
import {
  Button,
  ToggleButton,
  Range,
  Select,
  Input,
} from "../../../../../../teacherComponents";
import {
  DefaultMediaExerciseOptions,
  MaximumMediaCharactersAmount,
  MediaExerciseTypes,
  MediaTypes,
  QuestionsRange,
} from "../../../../data/constants";
import { LanguageLevels } from "../../../../../../data/common";
import { GenerateText, SelectMedia } from "..";
import cl from "./MediaOptions.module.css";

const TICKS_IN_SECOND = 10000000;

const MediaOptions = ({ options, setOptions, onCreate }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { currentTrack, generatedText } = exercisesStore;
  const { title, description, type, difficulty, mediaType, sentencesAmount } =
    options;

  const [errorMessage, setErrorMessage] = useState("");

  const PresentationOptions = [
    {
      name: t("exercises.transcription_title"),
      value: t("exercises.transcription_description"),
    },
    {
      name: t("exercises.short_summary_title"),
      value: t("exercises.short_summary_description"),
    },
    {
      name: t("exercises.creative_writing_title"),
      value: t("exercises.creative_writing_description"),
    },
    {
      name: t("exercises.paraphrasing_title"),
      value: t("exercises.paraphrasing_description"),
    },
    {
      name: t("exercises.personal_reaction_title"),
      value: t("exercises.personal_reaction_description"),
    },
    {
      name: t("exercises.opinion_piece_title"),
      value: t("exercises.opinion_piece_description"),
    },
    {
      name: t("exercises.character_analysis_title"),
      value: t("exercises.character_analysis_description"),
    },
    {
      name: t("exercises.analysis_essay_title"),
      value: t("exercises.analysis_essay_description"),
    },
    {
      name: t("exercises.comparative_essay_title"),
      value: t("exercises.comparative_essay_description"),
    },
  ];

  const resetState = () => {
    exercisesStore.setGeneratedText(null);
    setOptions(DefaultMediaExerciseOptions);
  };

  const getIsDisabled = () => {
    if (!type) return true;
    if (!difficulty) return true;
    if (
      mediaType === MediaTypes.GeneratedText &&
      !exercisesStore.generatedText?.length
    )
      return true;
    if (type === MediaExerciseTypes.Presentation && (!title || !description))
      return false;
  };

  const setSentencesAmount = (e) => {
    setOptions({ ...options, sentencesAmount: e.target.value });
  };

  const setTitle = (title) => {
    setOptions({ ...options, title });
  };

  const setDescription = (description) => {
    setOptions({ ...options, description });
  };

  const setDifficulty = (difficulty) => {
    setOptions({ ...options, difficulty });
  };

  const getText = () => {
    return mediaType === MediaTypes.GeneratedText
      ? exercisesStore.generatedText
      : exercisesStore.trackText;
  };

  const isRange = () =>
    ![MediaExerciseTypes.Summary, MediaExerciseTypes.Presentation].includes(
      options.type
    );

  const [getChatResponse, isChatLoading, chatError, resetError] = useFetching(
    async () => {
      const withMedia = ![MediaTypes.GeneratedText].includes(mediaType);
      const withInterval = ![
        MediaTypes.Text,
        MediaTypes.GeneratedText,
      ].includes(mediaType);

      const { data } = await ExerciseService.createExercise({
        ...options,
        trackId: withMedia ? exercisesStore.currentTrack : null,
        trackInterval: withInterval
          ? exercisesPlayer.currentRange.map((r) => r / TICKS_IN_SECOND)
          : null,
        text: getText(),
        difficulty: LanguageLevels.indexOf(difficulty),
        title,
        description,
        lang,
      });
      if (data) {
        onCreate(data);
      }
    }
  );

  const getCurrentCharactersAmount = () => {
    const text =
      mediaType === MediaTypes.GeneratedText
        ? exercisesStore.generatedText
        : exercisesStore.trackText;
    if (!text) return <>{"0"}</>;
    const amount = text.split("").length;
    const className = amount > MaximumMediaCharactersAmount ? cl.limit : cl.ok;
    return <span className={className}>{amount}</span>;
  };

  const isReadyToGenerateExercise = () => {
    if (mediaType === MediaTypes.GeneratedText && generatedText) return true;
    if (mediaType !== MediaTypes.GeneratedText && currentTrack) return true;
    return false;
  };

  useEffect(() => {
    if (isRange()) {
      setOptions({ ...options, sentencesAmount: QuestionsRange.Default });
    }
  }, [options.mediaType]);

  useEffect(() => {
    setErrorMessage(chatError?.message);
  }, [chatError]);

  useEffect(() => {
    const range = exercisesPlayer.currentRange;
    if (!range?.length) {
      exercisesStore.setTrackText(
        exercisesPlayer.trackSentences?.map((t) => t.text).join(" ")
      );
      return;
    }

    const segment = exercisesPlayer.trackSentences?.filter(
      (t) => t.offset >= range[0] && t.offset <= range[1]
    );
    exercisesStore.setTrackText(segment?.map((t) => t.text).join(" "));
  }, [exercisesPlayer.currentRange]);

  useEffect(
    () => () => {
      exercisesStore.resetTrackParams();
      exercisesPlayer.resetMediaParams();
    },
    []
  );

  useEffect(() => {
    exercisesPlayer.setMediaRange(exercisesPlayer.currentRange);
  }, [mediaType]);

  return (
    <div className={cl.exerciseOptions}>
      {type === MediaExerciseTypes.Presentation && (
        <>
          <div className={cl.optionsContainer}>
            <Input
              variant={"outlinedSmall"}
              placeholder={t("exercises.exercise_title")}
              value={title}
              onChange={setTitle}
            />
            <Select
              label={t("exercises.presentation_task")}
              value={description}
              onChange={setDescription}
              variant={"light"}
              options={PresentationOptions}
              height={200}
              style={{ minWidth: 300 }}
            />
          </div>
          {description && (
            <p className={cl.label}>{`${t(
              "exercises.presentation_task"
            )}: ${description}`}</p>
          )}
        </>
      )}
      <GenerateText mediaType={mediaType} />
      <SelectMedia onBack={resetState} mediaType={mediaType} />
      {isReadyToGenerateExercise() &&
        (errorMessage ? (
          <div className={cl.optionsContainer}>
            <p className={cl.errorMessage}>{`${t(
              "dictionary_administration.generation_error"
            )} (${errorMessage})`}</p>
            <Button
              text={t("buttons.reset")}
              variant={"red"}
              onClick={resetError}
            />
          </div>
        ) : (
          <>
            <p className={cl.infoText}>
              {`${t("exercises.limit_info")} ${t("exercises.limit_current")}: `}
              {getCurrentCharactersAmount()}
            </p>
            {isRange() && (
              <div>
                <p className={cl.label}>{`${t(
                  type === MediaExerciseTypes.SentencesOrder
                    ? "exercises.sentence_count"
                    : "exercises.question_count"
                )} (${sentencesAmount ?? QuestionsRange.Default})`}</p>
                <Range
                  style={{ marginTop: "auto" }}
                  value={sentencesAmount ?? QuestionsRange.Default}
                  min={QuestionsRange.Min}
                  step={QuestionsRange.Step}
                  max={QuestionsRange.Max}
                  onChange={setSentencesAmount}
                />
              </div>
            )}
            <div className={cl.toggleContainer}>
              <p className={cl.label}>{t("exercises.exercise_level")}</p>
              <ToggleButton
                label={t("users.level")}
                options={LanguageLevels}
                selectedOption={options.difficulty}
                variant={"filled"}
                setOption={setDifficulty}
              />
            </div>
            <div className={cl.optionsContainer}>
              <Button
                style={{ margin: "0 auto" }}
                variant={"purple"}
                text={t("exercises.generate_exercise")}
                isDisabled={getIsDisabled()}
                onClick={getChatResponse}
                isLoading={isChatLoading}
              />
            </div>
          </>
        ))}
    </div>
  );
};

export default observer(MediaOptions);
