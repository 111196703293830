import React from "react";
import cl from "./MicroButton.module.css";

const MicroButton = ({
  icon,
  size,
  text,
  variant,
  onClick,
  style,
  isDisabled,
}) => {
  const classNames = [cl.button, cl[size], cl[variant]];

  return (
    <button
      style={style}
      className={classNames.join(" ")}
      disabled={isDisabled}
      onClick={onClick}
    >
      {icon && <div className={cl.icon}>{icon}</div>}
      {text}
    </button>
  );
};

export default MicroButton;
