import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyModal from "../../../../../../components/UI/MyModal/MyModal";
import { useFetching } from "../../../../../../hooks/useFetching";
import ProgressService from "../../../../../../api/ProgressService";
import { Loader } from "../../../../../../UI";
import { ReactComponent as IconFolder } from "../../../../../../assets/svg/lessons-folder.svg";
import cl from "./SelectTargetVocabulary.module.css";

const SelectTargetVocabulary = ({ visible, setVisible, onSelect }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [categories, setCategories] = useState([]);

  const [getCategories, isLoading] = useFetching(async () => {
    const { data: categoriesData } = await ProgressService.getWordsCategories();

    const categoriesWithWords = await Promise.all(
      categoriesData.items.map(async (category) => {
        const { data: categoryWordsData } =
          await ProgressService.getCategoryWords({
            categoryId: category.id,
            lang,
          });

        return {
          ...category,
          words: categoryWordsData.items,
          wordsString: categoryWordsData.items.map((w) => w.word).join(", "),
        };
      })
    );

    setCategories(categoriesWithWords);
  });

  const handleClick = (category) => {
    onSelect(category.wordsString);
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      getCategories();
    } else {
      setCategories([]);
    }
  }, [visible]);

  return (
    <MyModal
      visible={visible}
      setVisible={setVisible}
      className={cl.selectPackModal}
    >
      {isLoading ? (
        <Loader style={{ margin: "auto" }} />
      ) : (
        <>
          <p className={cl.modalTitle}>{t("exercises.select_vocabulary")}</p>
          {categories.map((c) => (
            <div
              className={cl.categoryCard}
              key={c.id}
              onClick={() => handleClick(c)}
            >
              <div className={cl.preview}>
                <div className={cl.folder}>
                  <IconFolder />
                </div>
                <p className={cl.count}>{c?.words?.length ?? "?"}</p>
              </div>
              <div className={cl.cardInner}>
                <p className={cl.title}>{c.title}</p>
                <div className={cl.words}>
                  {c.words.map((w) => (
                    <span className={cl.word} key={`${c.id}_${w.word}`}>
                      {w.word}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </MyModal>
  );
};

export default SelectTargetVocabulary;
