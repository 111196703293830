import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { auth } from "../../App";
import { useFetching } from "../../hooks/useFetching";
import teachersStore from "./store/teachersStore";
import UserService from "../../api/UserService";
import { TeachersGrid, TeachersSearchBar } from "./components";
import TeacherLessons from "../TeacherLessons/TeacherLessons";
import { LessonsTitle } from "../TeacherLessons/components";

const Teachers = () => {
  const { currentTeacher, difficulty, searchString } = teachersStore;

  const [teachers, setTeachers] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const [getTeachers, isLoading] = useFetching(async () => {
    const { data } = await UserService.getTeachers();
    const items = data?.items ?? [];
    setTeachers(items.filter((t) => t.id !== auth.user?.id));
  });

  useEffect(() => {
    getTeachers();
  }, []);

  useEffect(() => {
    setFiltered(
      teachers.filter((s) => {
        const matchesName = searchString
          ? s.name.toLowerCase().includes(searchString.toLowerCase())
          : true;

        const matchesDifficulty = difficulty ? s.level === difficulty : true;

        return matchesName && matchesDifficulty;
      })
    );
  }, [teachers, searchString]);

  useEffect(
    () => () => {
      teachersStore.setCurrentTeacher(null);
    },
    []
  );

  return (
    <>
      <LessonsTitle />
      {currentTeacher ? (
        <TeacherLessons teacher={currentTeacher} />
      ) : (
        <>
          <TeachersSearchBar />
          <TeachersGrid items={filtered} />
        </>
      )}
    </>
  );
};

export default observer(Teachers);
