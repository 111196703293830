import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Button, ViewTitle } from "../../../../teacherComponents";
import teachersStore from "../../../Teachers/store/teachersStore";
import { ReactComponent as IconStar } from "../../../../assets/svg/lessons-star.svg";

const LessonsTitle = () => {
  const { t } = useTranslation();
  const { currentTeacher } = teachersStore;

  const handleTeacherClose = () => {
    teachersStore.setCurrentTeacher(null);
  };

  const getViewText = () => {
    if (currentTeacher)
      return `${t("exercises.lessons")} ${currentTeacher.name}`;

    return t(`navbar.teachers`);
  };

  const getViewChildren = () => {
    if (!currentTeacher) return null;

    return (
      <>
        <Button
          style={{ pointerEvents: "none", padding: "12px" }}
          icon={<IconStar />}
          variant={"purple"}
          text={currentTeacher.rating ?? 0}
        />
        <Button
          style={{ pointerEvents: "none", padding: "12px" }}
          variant={"grey"}
          text={`${currentTeacher.amount ?? 0} ${t("exercises.lessons")}`}
        />
        <Button
          variant={"red"}
          text={t("buttons.back")}
          onClick={handleTeacherClose}
        />
      </>
    );
  };

  return <ViewTitle text={getViewText()} children={getViewChildren()} />;
};

export default observer(LessonsTitle);
