import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../../../store/exercisesStore";
import exercisesPlayer from "../../../../store/exercisesPlayer";
import { Button } from "../../../../../../teacherComponents";
import { MediaTypes } from "../../../../data/constants";
import { EditMedia } from "..";
import { SelectVideoModal } from "../../../../components";
import { ReactComponent as IconCloud } from "../../../../../../assets/svg/lessons-upload.svg";
import cl from "./SelectMedia.module.css";

const SelectMedia = ({ mediaType, onBack }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(true);

  const renderResetRange = () => {
    if (!exercisesPlayer.currentRange) return false;
    const [start, end] = exercisesPlayer.currentRange;
    if (end === 0) return false;

    if (start > 0 || end < exercisesPlayer.trackLength) return true;

    return false;
  };

  const getMediaControls = () => {
    const controls = [
      <Button
        text={t("exercises.media_change")}
        variant={"grey"}
        onClick={() => setVisible(true)}
      />,
    ];
    if (renderResetRange()) {
      controls.push(
        <Button
          text={t("exercises.reset_range")}
          style={{ minWidth: 120 }}
          variant={"red"}
          onClick={() => {
            exercisesPlayer.resetRange();
          }}
        />
      );
    } else {
      controls.push(<div style={{ minWidth: 120 }} />);
    }

    return controls;
  };

  if (mediaType === MediaTypes.GeneratedText) return <></>;

  return (
    <>
      {!exercisesStore.currentTrack && (
        <div className={cl.addButtonContainer}>
          <Button
            style={{ gap: 12 }}
            icon={<IconCloud style={{ minWidth: 16 }} />}
            text={t("exercises.media_type")}
            variant={"grey"}
            onClick={() => setVisible(true)}
          />
        </div>
      )}

      {exercisesStore.currentTrack && (
        <EditMedia mediaType={mediaType} controls={getMediaControls()} />
      )}

      <SelectVideoModal
        visible={visible}
        setVisible={setVisible}
        onClick={exercisesStore.setCurrentTrack}
        withCategorySelector={true}
      />
    </>
  );
};

export default observer(SelectMedia);
