import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import lessonsStore from "../../../../store/lessonsStore";
import { useFetching } from "../../../../../../hooks/useFetching";
import ExerciseService from "../../../../../../api/ExerciseService";
import cl from "./CloneLesson.module.css";
import DemoPageLoader from "../../../../../../components/UI/DemoPageLoader/DemoPageLoader";

const CloneLesson = ({ lessonId }) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);
  const { themes } = lessonsStore;

  const [cloneLesson, isLoading] = useFetching(async () => {
    const { data } = await ExerciseService.cloneLesson({ id: lessonId });
    syncLessons(data);

    setCopied(true);
  });

  const syncLessons = async (data) => {
    const lessonData = await ExerciseService.getLesson({ id: data.id });
    const newLesson = { ...data, chapters: lessonData.data.chapters };

    let theme = themes.find((t) => t.title === data.topic);

    if (!theme || !theme.id) {
      theme = {
        id: uuid(),
        title: data.topic,
        lessons: [newLesson],
      };
      lessonsStore.addTheme(theme);
      return;
    }

    lessonsStore.addLesson(theme.id, newLesson);
  };

  return (
    <div
      className={`${cl.addButton} ${copied ? cl.copied : ""}`}
      onClick={cloneLesson}
    >
      {isLoading ? (
        <DemoPageLoader size={14} color={"#ffffff"} />
      ) : (
        t(copied ? "exercises.copy_success" : "exercises.copy_lesson")
      )}
    </div>
  );
};

export default observer(CloneLesson);
