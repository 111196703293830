export const DictionaryCodes = {
  NotFound: "044",
  InvalidWord: "045",
  NoTranslation: "046",
  Ok: 200,
  Created: 201,
  TooEarly: 425,
};

export const PendingWordsKey = "dictionaryPendingWords";
