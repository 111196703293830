import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../hooks/useFetching";
import UserService from "../../api/UserService";
import MyModal from "../UI/MyModal/MyModal";
import Loader from "../UI/Loader/Loader";
import { LabeledInput, PrimaryButton } from "../../UI";
import cl from "./SupportModal.module.css";

const SupportModal = ({ showSupportModal, setShowSupportModal }) => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [problemDescription, setProblemDescription] = useState("");
  const [notEmpty, setNotEmpty] = useState(false);
  const [complete, setComplete] = useState(false);

  const [sendMessage, isLoading, error, resetError] = useFetching(async () => {
    const res = await UserService.support({
      subject,
      problemDescription,
      email,
    });
    const data = res.data;
    if (data.status === "success") {
      setShowSupportModal(false);
      setComplete(true);
    }
  });

  const handleClickButton = () => {
    sendMessage();
    setSubject("");
    setEmail("");
    setProblemDescription("");
  };

  useEffect(() => {
    if (subject && problemDescription && email) {
      setNotEmpty(true);
    } else {
      setNotEmpty(false);
    }
  }, [subject, problemDescription, email]);

  return (
    <>
      <MyModal visible={complete} title={t("success.support")}>
        <div className={cl.buttons}>
          <PrimaryButton
            text={t("buttons.ok")}
            variant={"dark"}
            onClick={() => {
              setComplete(false);
            }}
          />
        </div>
      </MyModal>

      <MyModal visible={showSupportModal} title={t("footer.support")}>
        {isLoading && <Loader />}
        <LabeledInput
          label={t("email")}
          value={email}
          onChange={(val) => {
            setEmail(val);
          }}
        />
        <LabeledInput
          label={t("footer.subject")}
          value={subject}
          onChange={(val) => {
            setSubject(val);
          }}
        />
        <LabeledInput
          label={t("footer.description")}
          value={problemDescription}
          onChange={setProblemDescription}
        />
        <div className={cl.buttons}>
          <PrimaryButton
            text={t("buttons.back")}
            onClick={() => {
              setShowSupportModal(false);
            }}
          />
          <PrimaryButton
            isDisabled={!notEmpty}
            variant={"dark"}
            text={t("buttons.send")}
            onClick={() => {
              handleClickButton();
            }}
          />
        </div>
      </MyModal>
    </>
  );
};

export default SupportModal;
