import React, { useRef, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import player from "../../store/player";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import MyModal from "../../components/UI/MyModal/MyModal";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import PlayerControls from "../../components/PlayerControls/PlayerControls";
import YoutubePlayer from "../../components/YoutubePlayer/YoutubePlayer";
import { AddTrackInput } from "./components";
import { TrackCard } from "../Library/components";
import { ROUTES } from "../../router";
import { PrimaryButton } from "../../UI";

const TICKS_IN_SECOND = 10000000;

const AddTrack = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const stateFromNavigate = location.state || {};
  const [sourceLang, setSourceLang] = useState([]);
  const [showLangUnsupported, setShowLangUnsupported] = useState(false);
  const usupportedLang = useRef(false);
  const videoLang = useRef(null);
  const videoTitle = useRef(null);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [showLangWarn, setShowLangWarn] = useState(false);
  const [userLangError, setUserLangError] = useState(false);
  const [sourceLangError, setSourceLangError] = useState(false);
  const [url, setUrl] = useState("");
  const [track, setTrack] = useState(null);

  const reset = () => {
    setUrl("");
    setSourceLang([]);
    setTrack(null);
  };

  const handleLibraryButton = () => {
    navigate(ROUTES.library);
  };

  useEffect(() => {
    if (stateFromNavigate.track) {
      setTrack({
        isYoutube: stateFromNavigate.track.isYoutube,
        id: stateFromNavigate.track.id,
        reason: "success",
        json: stateFromNavigate.track,
      });
    }
  }, []);

  useEffect(() => {
    if (!track) return;
    player.setYtVideoId(track.externalId);
    player.setTrackLength(track.duration * TICKS_IN_SECOND);
    setSourceLang(track.lang || sourceLang);
  }, [track]);

  useEffect(() => {
    if (sourceLang.length) {
      setSourceLangError(sourceLang.length ? false : sourceLangError);
    }
  }, [sourceLang]);

  const previewModal = useMemo(() => {
    if (showPreviewPopup) {
      return (
        <MyModal
          wrapTitle={true}
          style={{ maxWidth: 660 }}
          visible={showPreviewPopup}
          title={track?.json?.title}
          setVisible={setShowPreviewPopup}
        >
          {track?.json?.isYoutube ? <YoutubePlayer /> : <AudioPlayer />}
          <PlayerControls />
          <PrimaryButton
            text={t("buttons.ok")}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            variant={"dark"}
            onClick={() => {
              setShowPreviewPopup(false);
            }}
          />
        </MyModal>
      );
    } else return null;
  }, [track, showPreviewPopup]);

  return (
    <PageWrapper
      isArrowed={stateFromNavigate.needBackArrow}
      style={{ paddingTop: 20, overflowY: "auto" }}
    >
      {track ? (
        <TrackCard
          isAdding={true}
          onThumbnailClick={() => setShowPreviewPopup(true)}
          trackDeletedCb={reset}
          data={track}
          json={track}
          style={{ marginBottom: 10 }}
        />
      ) : (
        <AddTrackInput url={url} setUrl={setUrl} setTrack={setTrack} />
      )}
      {track && (
        <div
          style={{ display: "flex", gap: 13, marginBottom: 30, marginTop: 30 }}
        >
          <PrimaryButton
            text={t("add_track.add_another_track")}
            variant={"dark"}
            onClick={reset}
          />
          {stateFromNavigate.needBackArrow ? (
            <PrimaryButton
              text={t("buttons.back")}
              onClick={() => navigate(-1)}
            />
          ) : (
            <PrimaryButton
              text={t("add_track.go_to_track_list")}
              onClick={handleLibraryButton}
            />
          )}
        </div>
      )}
      <MyModal
        visible={showLangWarn}
        setVisible={setShowLangWarn}
        title={t("add_track.is_language_correct")}
      >
        <div style={{ maxWidth: 450, marginBottom: 20 }}>
          <p style={{ fontWeight: 600, marginBottom: 10, textAlign: "center" }}>
            {videoTitle.current}
          </p>
          <p style={{ marginBottom: 10, textAlign: "center" }}>
            {t("add_track.language_problem", {
              1: sourceLang,
              2: videoLang.current,
            })}
          </p>
        </div>
        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 10 }}
        >
          <PrimaryButton
            variant={"dark"}
            text={t("add_track.change_language")}
            onClick={() => {
              setShowLangWarn(false);
              videoLang.current = null;
              videoTitle.current = null;
            }}
          />
        </div>
      </MyModal>
      <MyModal
        visible={showLangUnsupported}
        setVisible={setShowLangUnsupported}
        title={t("add_track.usupported_lang")}
      >
        <p style={{ marginBottom: 10, textAlign: "center" }}>
          {`${t("add_track.unupported_lang_text")} ${usupportedLang.current}`}
        </p>
        <PrimaryButton
          style={{ marginLeft: "auto", marginRight: "auto" }}
          variant={"dark"}
          text={t("buttons.ok")}
          onClick={() => {
            setShowLangUnsupported(false);
            usupportedLang.current = false;
          }}
        />
      </MyModal>
      <MyModal
        visible={userLangError !== false}
        title={t("add_track.dont_have_this_lang")}
        desc={t("add_track.dont_have_this_lang_desc", {
          language: userLangError,
        })}
      >
        <PrimaryButton
          style={{ marginLeft: "auto", marginRight: "auto" }}
          variant={"dark"}
          text={t("buttons.ok")}
          onClick={() => {
            setUserLangError(false);
          }}
        />
      </MyModal>
      {previewModal}
    </PageWrapper>
  );
};

export default observer(AddTrack);
