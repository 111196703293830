import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useFetching } from "../../../../hooks/useFetching.js";
import CategoryService from "../../../../api/CategoryService.js";
import exercisesStore from "../../store/exercisesStore.js";
import {
  Checkbox,
  ControlButton,
  Input,
  Select,
  Separator,
  ToggleButton,
  Toolbar,
} from "../../../../teacherComponents/index.js";
import { LanguageLevels } from "../../../../data/common.js";
import {
  GrammarExerciseTypes,
  MediaExerciseTypes,
} from "../../data/constants.js";
import { Chapters } from "../../../TeacherLessons/data/constants.js";

const ContentSearchBar = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [category, setCategory] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);

  const { unassigned, difficulty, type, view } = exercisesStore;

  const exercisesTypeOptions = [
    ...Object.values(GrammarExerciseTypes),
    ...Object.values(MediaExerciseTypes),
  ].map((type) => ({ value: type, name: t(`exercises.${type}`) }));

  const [getCategories] = useFetching(async () => {
    const { data } = await CategoryService.getCategories({
      lang,
    });
    setCategoryOptions(
      data.map((c) => ({ value: c.id, name: c.translation || c.title }))
    );
  });

  const getSearchString = () => {
    switch (view) {
      case Chapters.Track: {
        return exercisesStore.trackSearch;
      }
      case Chapters.Situation: {
        return exercisesStore.situationSearch;
      }
      case Chapters.Exercise: {
        return exercisesStore.exerciseSearch;
      }
      case Chapters.Dictionary: {
        return exercisesStore.dictionarySearch;
      }
      default:
        return "";
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Toolbar>
      <ToggleButton
        nullable
        options={LanguageLevels}
        selectedOption={difficulty}
        setOption={exercisesStore.setDifficulty}
        variant={"round"}
      />
      {view === Chapters.Exercise && (
        <Select
          label={t("exercises.type")}
          style={{ minWidth: 220 }}
          variant={"white"}
          value={type}
          onChange={exercisesStore.setType}
          options={
            type
              ? [
                  { value: "", name: t("podcasts.all") },
                  ...exercisesTypeOptions,
                ]
              : exercisesTypeOptions
          }
        />
      )}
      {view === Chapters.Track && (
        <Select
          label={t("tags_cont.choose_category")}
          style={{ minWidth: 220 }}
          variant={"white"}
          value={category}
          onChange={setCategory}
          options={
            category
              ? [{ value: "", name: t("podcasts.all") }, ...categoryOptions]
              : categoryOptions
          }
        />
      )}
      <Checkbox
        label={t("exercises.not_used_chapter")}
        value={unassigned}
        onChange={exercisesStore.setUnassigned}
      />
      <Separator />
      <Input
        search
        placeholder={t("library.search")}
        value={getSearchString()}
        onChange={exercisesStore.setSearchString}
      />

      <Separator />
      <ControlButton
        type="help"
        onClick={() => {
          alert("this button has no functionality yet");
        }}
      />
    </Toolbar>
  );
};

export default observer(ContentSearchBar);
