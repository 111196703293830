import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { PrimaryInput } from "../../../../UI";
import lessonsStore from "../../store/lessonsStore";
import cl from "./AddTheme.module.css";

const AddTheme = () => {
  const { t } = useTranslation();
  const { isAddLesson } = lessonsStore;

  const ref = useRef();

  const [title, setTitle] = useState();

  const handleAdd = () => {
    lessonsStore.addTheme({
      id: uuid(),
      title,
      lessons: [],
    });
    lessonsStore.setIsAddLesson(false);
  };

  useEffect(() => {
    setTitle("");
  }, [isAddLesson]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (title) handleAdd();
        lessonsStore.setIsAddLesson(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, title]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        if (title) handleAdd();
        lessonsStore.setIsAddLesson(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [title]);

  return isAddLesson ? (
    <div className={cl.emptyCard} ref={ref}>
      <PrimaryInput
        value={title}
        placeholder={t("exercises.theme_title")}
        onChange={setTitle}
        autoFocus={true}
      />
    </div>
  ) : (
    <div
      className={cl.addTheme}
      onClick={() => {
        lessonsStore.setIsAddLesson(true);
      }}
    >
      <p>{`+ ${t("exercises.add_theme")}`}</p>
    </div>
  );
};

export default observer(AddTheme);
