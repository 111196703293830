import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import exercisesStore from "../../../TeacherContent/store/exercisesStore";
import lessonsStore from "../../store/lessonsStore";
import teachersStore from "../../../Teachers/store/teachersStore";
import ExerciseService from "../../../../api/ExerciseService";
import { Loader } from "../../../../UI";
import { AddChapter, CloneLesson, ModalHeader } from "./components";
import Player from "../../../Player/Player";
import { CloseButton } from "../../../../teacherComponents";
import { ChapterSection } from "..";
import { Preview } from "../../../TeacherContent/views/ExercisesView/components";
import { CategoryWords } from "../../../TeacherContent/views/DictionaryView/components";
import { Situation } from "../../../TeacherContent/views/SituationsView/components";
import { FileTypes } from "../../../../components/UI/FileUploader/FileUploader";
import { ReactComponent as IconEyes } from "../../../../assets/svg/lessons-eyes.svg";
import { Chapters, Views } from "../../data/constants";
import cl from "./LessonModal.module.css";

const LessonModal = () => {
  const { t, i18n } = useTranslation();
  const { currentStudent, view } = exercisesStore;
  const {
    currentLesson,
    currentStudentLessons,
    currentLessonLoading,
    currentChapter,
  } = lessonsStore;
  const { currentTeacher } = teachersStore;

  const isTeacher = view === Views.Teachers && currentTeacher;

  const pdfs = currentLesson?.pdfs ?? [];

  const pictures = currentLesson?.pictures ?? [];

  const [currentWord, setCurrentWord] = useState();
  const [tracks, setTracks] = useState([]);
  const [situations, setSituations] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [dictionaries, setDictionaries] = useState([]);
  const [files, setFiles] = useState([]);

  const chaptersList = [
    {
      items: exercises,
      type: Chapters.Exercise,
    },
    {
      items: situations,
      type: Chapters.Situation,
    },

    {
      items: tracks,
      type: Chapters.Track,
    },
    {
      items: dictionaries,
      type: Chapters.Dictionary,
    },
    {
      items: files,
      type: Chapters.File,
    },
  ];

  const [addChapter] = useFetching(async ({ chapterId, chapterType }) => {
    await ExerciseService.addChapterToLesson({
      lessonId: currentLesson.id,
      chapterId,
      chapterType,
      order: 0,
    });

    const { data } = await ExerciseService.getLesson({ id: currentLesson.id });

    lessonsStore.updateLesson({
      ...currentLesson,
      chapters: data.chapters,
    });
  });

  const [updateLesson] = useFetching(async ({ id, ...params }) => {
    await ExerciseService.updateLesson({ id, ...params });
  });

  const [deleteLesson] = useFetching(async ({ id }) => {
    try {
      await ExerciseService.removeExercise({
        id,
      });
    } catch (_e) {
      try {
        await ExerciseService.deleteExercise({
          id,
        });
      } catch (_e) {}
    }
  });

  const handleLessonUpdate = async (params) => {
    const { title, age, difficulty } = params;

    await updateLesson({
      id: currentLesson.id,
      title,
      age,
      difficulty,
    });
    lessonsStore.updateLesson({
      ...currentLesson,
      title,
      age,
      difficulty,
    });
  };

  const handleClose = () => {
    lessonsStore.setCurrentLesson(null);
    lessonsStore.setCurrentChapter({});
  };

  const handleLessonDelete = async () => {
    if (!currentLesson) return;
    lessonsStore.deleteThemeLesson(currentLesson.id);

    await deleteLesson({ id: currentLesson.id });
  };

  const handleFileUpload = (file) => {
    if (file.name.split(".")[1] === FileTypes.Pdf) {
      updateLesson({ id: currentLesson.id, pdf: file });
    } else {
      updateLesson({ id: currentLesson.id, picture: file });
    }
  };

  const handleChapterDelete = (deletedChapterId) => {
    const newLesson = {
      ...currentLesson,
      chapters: currentLesson?.chapters.filter(
        (c) => c.id !== deletedChapterId
      ),
    };
    lessonsStore.updateLesson(newLesson);
    if (currentChapter && currentChapter.id === deletedChapterId) {
      lessonsStore.setCurrentChapter({});
    }
  };

  const renderCurrentChapter = () => {
    switch (currentChapter.type) {
      case Chapters.Track:
        return (
          <Player
            key={"modalView"}
            track={{ id: currentChapter?.id, title: currentChapter?.title }}
          />
        );
      case Chapters.Situation: {
        return <Situation situation={currentChapter} />;
      }
      case Chapters.Exercise: {
        return <Preview exerciseId={currentChapter.id} />;
      }
      case Chapters.Dictionary: {
        return (
          <CategoryWords
            category={currentChapter}
            lang={i18n.language}
            currentWord={currentWord}
            setCurrentWord={setCurrentWord}
          />
        );
      }
      default:
        return (
          <div className={cl.emptyAlert}>
            <IconEyes />
            <p className={cl.alertTitle}>{t(`exercises.select_chapter`)}</p>
          </div>
        );
    }
  };

  const getModalClassName = () => {
    const classNames = [cl.lessonModal];
    if (currentStudent) {
      const status =
        currentStudentLessons.find((l) => l.lessonId === currentLesson.id)
          ?.status ?? "";
      classNames.push(cl[status]);
    }
    return classNames.join(" ");
  };

  useEffect(() => {
    if (!currentLesson) return;
    const chapters = currentLesson.chapters ?? [];
    setFiles([...pdfs, ...pictures]);
    if (!chapters.length) return;

    setTracks(chapters.filter((c) => c.type === Chapters.Track));
    setSituations(chapters.filter((c) => c.type === Chapters.Situation));
    setExercises(chapters.filter((c) => c.type === Chapters.Exercise));
    setDictionaries(chapters.filter((c) => c.type === Chapters.Dictionary));
    lessonsStore.setCurrentChapter(chapters[0]);
    return () => {
      view === Views.Students && exercisesStore.setCurrentStudent(null);
    };
  }, [currentLesson]);

  if (!currentLesson) return null;

  return (
    <div className={cl.overlay} onClick={handleClose}>
      <div className={getModalClassName()} onClick={(e) => e.stopPropagation()}>
        <ModalHeader
          lesson={currentLesson}
          student={currentStudent}
          onUpdate={handleLessonUpdate}
          onDelete={handleLessonDelete}
        />
        {currentLessonLoading ? (
          <Loader style={{ margin: "auto" }} />
        ) : (
          <div className={cl.mainContainer}>
            <div className={cl.aside}>
              <div className={cl.chaptersList}>
                {chaptersList.map((c) => c.items).flat().length !== 0 ? (
                  <div className={cl.scrollContainer}>
                    {chaptersList.map((c) => (
                      <ChapterSection
                        key={c.type}
                        chapters={c.items}
                        type={c.type}
                        lessonId={currentLesson.id}
                        onDelete={handleChapterDelete}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={cl.emptyAlert}>
                    <p className={cl.alertTitle}>
                      {t("exercises.empty_lesson")}
                    </p>
                    {!currentStudent && (
                      <p className={cl.alertSubtitle}>
                        {t("exercises.add_chapter_hint")}
                      </p>
                    )}
                  </div>
                )}
              </div>
              {isTeacher && <CloneLesson lessonId={currentLesson.id} />}
              {!isTeacher && !currentStudent && (
                <AddChapter
                  lang={i18n.language}
                  onAdd={addChapter}
                  onFileSelect={handleFileUpload}
                  alreadyAdded={{
                    dictionaries,
                    exercises,
                    situations,
                    tracks,
                  }}
                />
              )}
            </div>
            <div className={cl.chapterCard}>{renderCurrentChapter()}</div>
          </div>
        )}

        <CloseButton onClick={handleClose} />
      </div>
    </div>
  );
};

export default observer(LessonModal);
