import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import lessonsStore from "../../store/lessonsStore";
import { ChapterCard } from "../../../TeacherContent/components";
import { FileCard } from "../LessonModal/components";
import { Chapters } from "../../data/constants";
import { ReactComponent as IconDictionary } from "../../../../assets/svg/lessons-dictionary.svg";
import { ReactComponent as IconExercise } from "../../../../assets/svg/lessons-exercise.svg";
import { ReactComponent as IconSituation } from "../../../../assets/svg/lessons-situation.svg";
import { ReactComponent as IconTrack } from "../../../../assets/svg/lessons-track.svg";
import { ReactComponent as IconFile } from "../../../../assets/svg/lessons-file.svg";
import { ReactComponent as IconArrow } from "../../../../assets/svg/lessons-arrow.svg";
import cl from "./ChapterSection.module.css";

const ChapterSection = ({ chapters, lessonId, type, onDelete }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const { currentChapter } = lessonsStore;

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const renderIcon = () => {
    switch (type) {
      case Chapters.Track:
        return <IconTrack />;
      case Chapters.Situation:
        return <IconSituation />;
      case Chapters.Exercise:
        return <IconExercise />;
      case Chapters.Dictionary:
        return <IconDictionary />;
      case Chapters.File:
        return <IconFile />;
      default:
        return null;
    }
  };

  const renderChapters = () => {
    if (!isExpanded) return null;
    return type === Chapters.File ? (
      <div className={cl.files}>
        {chapters.map((file) => (
          <FileCard key={file} file={file} />
        ))}
      </div>
    ) : (
      chapters.map((chapter) => (
        <ChapterCard
          key={chapter.id}
          type={type}
          chapter={chapter}
          onDelete={onDelete}
          isActive={currentChapter?.id === chapter.id}
          setActive={lessonsStore.setCurrentChapter}
          lessonId={lessonId}
        />
      ))
    );
  };

  useEffect(() => {
    setIsExpanded(true);
  }, []);

  if (chapters.length === 0) return null;

  return (
    <div className={cl.chapterContainer}>
      <div className={cl.chapterTypeCont}>
        {renderIcon()}
        <p className={cl.chapterType}>{`${t(`exercises.${type}_view`)} ${
          !isExpanded ? `(${chapters.length})` : ""
        }`}</p>

        <div
          className={cl.control}
          onClick={toggleExpanded}
          style={{ transform: !isExpanded ? "rotate(180deg)" : "" }}
        >
          <IconArrow />
        </div>
      </div>
      {renderChapters()}
    </div>
  );
};

export default observer(ChapterSection);
