import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../store/exercisesStore";
import { useFetching } from "../../../../hooks/useFetching";
import ProgressService from "../../../../api/ProgressService";
import TrackService from "../../../../api/TrackService";
import {
  ChapterPlug,
  ChapterPreviewContainer,
  ChaptersList,
  ContentContainer,
  SelectVideoModal,
} from "../../components";
import { Player } from "../../..";
import { LanguageLevels } from "../../../../data/common";
import { Chapters } from "../../../TeacherLessons/data/constants";
import player from "../../../../store/player";

const TrackView = ({ isActive }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const { chaptersModalType, difficulty, trackSearch } = exercisesStore;

  const [tracks, setTracks] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [currentTrack, setCurrentTrack] = useState();

  const [getTracks, isLoading] = useFetching(async () => {
    const { data } = await TrackService.getFavorites({
      offset: 0,
      limit: 500,
      lang,
      difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
      searchString: trackSearch,
    });
    const tracks = data.items ?? [];
    setTracks(tracks);
    setCurrentTrack(tracks[0]);
  });

  const [addFavoriteTrack] = useFetching(async (trackId) => {
    const { data } = await ProgressService.addTrack({
      trackId,
      language: lang,
    });
    if (!data || !data.trackId) {
      getTracks();
      return;
    }
    const { data: trackData } = await TrackService.getTrack(data.trackId);
    setTracks([trackData, ...tracks]);
    setCurrentTrack(trackData);
  });

  const handleTrackDelete = (id) => {
    setTracks(tracks.filter((t) => t.id !== id));
  };

  const playerRenderAvailable = () =>
    isActive && chaptersModalType !== Chapters.Track;

  useEffect(() => {
    setCurrentTrack();
    getTracks();
    setIsFilter(difficulty || trackSearch);
  }, [lang, difficulty, trackSearch]);

  useEffect(() => {
    if (chaptersModalType === Chapters.Track) {
      player.playerRef?.destroy();
      player.setPlayerRef(null);
      player.setIsPlaying(false);
    }
  }, [chaptersModalType]);

  return (
    <ContentContainer view={Chapters.Track}>
      <ChaptersList
        type={Chapters.Track}
        isLoading={isLoading}
        chapters={tracks}
        current={currentTrack}
        setCurrent={setCurrentTrack}
        onDelete={handleTrackDelete}
        isFilter={isFilter}
      />
      {currentTrack ? (
        <ChapterPreviewContainer>
          {playerRenderAvailable() ? <Player track={currentTrack} /> : null}
        </ChapterPreviewContainer>
      ) : (
        <ChapterPlug />
      )}
      <SelectVideoModal
        visible={chaptersModalType === Chapters.Track}
        setVisible={exercisesStore.setChaptersModalType}
        onClick={addFavoriteTrack}
        alreadyAdded={tracks}
      />
    </ContentContainer>
  );
};

export default observer(TrackView);
