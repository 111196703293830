import React from "react";
import { useTranslation } from "react-i18next";
import cl from "./ToggleButton.module.css";

const ToggleButton = ({
  title,
  options,
  nullable,
  selectedOption,
  availableOptions,
  setOption,
  style,
  variant,
  withTranslations,
  tooltips,
  multiple,
  disabled,
  isGrid,
}) => {
  const { t } = useTranslation();

  const handleOptionClick = (option) => {
    if (
      availableOptions !== undefined &&
      !availableOptions?.includes(option) &&
      disabled
    )
      return;
    if (multiple) {
      setOption(
        selectedOption.includes(option)
          ? selectedOption.filter((o) => o !== option)
          : [...selectedOption, option]
      );
      return;
    }
    setOption(selectedOption === option && nullable ? null : option);
  };

  const getOptionClassName = (option) => {
    const classNames = [cl.option];
    if (!multiple && option === selectedOption) classNames.push(cl.active);
    if (multiple && selectedOption.includes(option)) classNames.push(cl.active);
    if (variant === "round") classNames.push(cl.round);
    if (availableOptions !== undefined && !availableOptions?.includes(option)) {
      if (disabled) classNames.push(cl.disabled);
      classNames.push(cl.unavailable);
    }
    return classNames.join(" ");
  };

  return (
    <div className={cl.toggleButtonCont} style={style}>
      {title && <p className={cl.sectionTitle}>{`${t(title)}:`}</p>}
      <div
        className={`${cl.optionsContainer} ${isGrid ? cl.grid : ""}`}
        style={{
          gridTemplateColumns: isGrid ? `repeat(${options.length}, 1fr)` : "unset",
        }}
      >
        {options.map((option, index) => (
          <div
            className={getOptionClassName(option)}
            key={`${option}_${index}`}
            onClick={() => handleOptionClick(option)}
          >
            {withTranslations
              ? t(`${withTranslations}.${option.toLowerCase()}`)
              : option}
            {tooltips && (
              <div className={cl.tooltip}>
                {t(`${withTranslations}.${option.toLowerCase()}_tooltip`)}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToggleButton;
