import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import cl from "./SummaryEditor.module.css";
import MyAdjustableTextarea from "../../../../../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import QuestionsLabel from "../../../Preview/components/QuestionsLabel/QuestionsLabel";

const SummaryEditor = ({ exerciseData, onChange }) => {
  const [options, setOptions] = useState([]);

  const handleOptionChange = (text, id) => {
    const newOptions = [...options].map((o) =>
      o.id === id ? { ...o, text } : o
    );
    onChange({ ...exerciseData, options: newOptions });
  };

  useEffect(() => {
    setOptions(exerciseData?.options ?? []);
  }, [exerciseData]);

  return (
    <div className={cl.editorCont}>
      <QuestionsLabel label={"edit_options"} />
      {options.map((o) => (
        <MyAdjustableTextarea
          className={cl.option}
          value={o.text}
          onChange={(text) => handleOptionChange(text, o.id)}
          key={o.id}
        />
      ))}
    </div>
  );
};

export default observer(SummaryEditor);
