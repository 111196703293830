import React from "react";
import { ReactComponent as IconSearch } from "../../assets/svg/lessons-search.svg";
import cl from "./Input.module.css";
import DemoPageLoader from "../../components/UI/DemoPageLoader/DemoPageLoader";

const Input = ({
  placeholder,
  value,
  onChange,
  onEnterKey,
  onBlur,
  onFocus,
  style,
  type,
  color,
  disabled,
  autoFocus,
  search,
  variant,
  isLoading,
}) => {
  const classNames = [cl.input];

  if (color) {
    color = Array.isArray(color) ? color : color.split(" ");
    color.forEach((el) => {
      classNames.push(cl[el]);
    });
  }

  if (variant) classNames.push(cl[variant]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
      if (onEnterKey) {
        onEnterKey(e);
      }
    }
  };

  const handleChange = (e) => {
    onChange(e.target.value, e);
  };

  return (
    <div className={cl.container}>
      {isLoading ? (
        <DemoPageLoader size={20} color="#e3e3e3" />
      ) : (
        <input
          disabled={disabled}
          style={style}
          placeholder={placeholder}
          value={value}
          type={type || "text"}
          className={classNames.join(" ")}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
        />
      )}
      {search && (
        <div className={cl.icon}>
          <IconSearch />
        </div>
      )}
    </div>
  );
};

export default Input;
