import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  StudentCalendar,
  StudentNotes,
  StudentContentNavigation,
  StudentKanban,
} from "..";
import { Views } from "../../data";
import cl from "./CurrentStudent.module.css";

const CurrentStudent = ({ student, comment, onChange, onClose }) => {
  const { name } = student;

  const [view, setView] = useState(Views.Plan);

  const renderView = () => {
    switch (view) {
      case Views.Notes:
        return (
          <StudentNotes name={name} comment={comment} onChange={onChange} />
        );
      case Views.Calendar:
        return <StudentCalendar />;
      case Views.Plan:
        return <StudentKanban student={student} onClose={onClose} />;
      default:
        return null;
    }
  };

  return (
    <div className={cl.currentStudent}>
      <StudentContentNavigation view={view} setView={setView} />
      {renderView()}
    </div>
  );
};

export default observer(CurrentStudent);
