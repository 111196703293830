import React, { useContext } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";
import usePageTitle from "../hooks/usePageTitle";
import { AuthContext } from "../App";
import { getDefaultRouteByRole, getRoutesByRole } from ".";

const AppRouter = () => {
  const { auth } = useContext(AuthContext);
  const { role } = auth;
  const routes = getRoutesByRole(role);
  const defaultRoute = getDefaultRouteByRole(role);

  usePageTitle(routes);

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          path={route.path}
          exact={route.exact}
          element={route.component}
          key={route.path}
        />
      ))}
      <Route path="*" element={<Navigate to={defaultRoute} replace />} />
    </Routes>
  );
};

export default observer(AppRouter);
