import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconEyes } from "../../../../../../assets/svg/lessons-eyes.svg";
import { ReactComponent as IconSound } from "../../../../../../assets/img/icon-sound_alt.svg";
import { Loader, PrimaryButton } from "../../../../../../UI";
import { URL } from "../../../../../../api/SituationsService";
import cl from "./Statements.module.css";

const Statements = ({ situation, isEmpty, isLoading, currentPlaying }) => {
  const { t } = useTranslation();

  const buttonsRef = useRef();

  const [active, setActive] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(new Audio());

  const handleAudioClick = () => {
    setTimeout(() => {
      setIsPlaying(true);
      audio.play();
    });
  };

  const handleStatementClick = async (statement, event) => {
    event.stopPropagation();
    setActive(statement);
  };

  const isActive = (statement) => statement.id === active?.id;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonsRef.current && !buttonsRef.current.contains(event.target)) {
        setActive(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [buttonsRef, active]);

  useEffect(() => {
    setIsPlaying(false);
    if (active) {
      audio.pause();
      audio.src = `${URL}${active.phraseVoice}`;
    } else {
      audio.src = "";
    }
  }, [active]);

  useEffect(() => {
    const handleAudioEnd = () => {
      setActive(null);
    };
    audio.addEventListener("ended", handleAudioEnd);

    return () => {
      audio.removeEventListener("ended", handleAudioEnd);
    };
  }, [audio]);

  const getClassName = (statement) => {
    const classNames = [];
    if (statement.actor === "A") classNames.push(cl.fromMe);
    if (statement.actor === "B") classNames.push(cl.fromThem);
    if (currentPlaying === statement.id || (isPlaying && isActive(statement)))
      classNames.push(cl.active);

    return classNames.join(" ");
  };

  if (isLoading) return <Loader style={{ margin: "auto" }} />;

  if (isEmpty)
    return (
      <div className={cl.emptyAlert}>
        <IconEyes />
        <p className={cl.alertTitle}>{t(`situations.empty_situation`)}</p>
      </div>
    );

  return (
    <div className={cl.statementsCont}>
      <div className={cl.speakerLabels}>
        <p className={cl.label}>{`${t("exercises.interlocutor")}: `}</p>
        <p className={cl.label}>{`${t("exercises.student")}: `}</p>
      </div>
      <div className={cl.statements}>
        {situation?.statements?.map((s) => (
          <div
            className={getClassName(s)}
            key={s.id}
            onClick={(e) => handleStatementClick(s, e)}
          >
            {s.phrase}
            <div
              className={`${cl.buttons} ${isActive(s) ? cl.active : ""}`}
              ref={isActive(s) ? buttonsRef : null}
            >
              {active?.phraseVoice && (
                <PrimaryButton
                  onClick={handleAudioClick}
                  style={{
                    padding: "9px 5px",
                    background: s.actor !== "A" ? "#ffffff" : "#f2f2f7",
                    boxShadow: "0px 1px 4px 0px #00000024",
                    borderRadius: 8,
                  }}
                  variant={"dark"}
                  icon={<IconSound className={cl.buttonIcon} />}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(Statements);
