import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { observer } from "mobx-react-lite";
import { v4 as uuid } from "uuid";
import MyAddButton from "../../../../../../../../components/UI/MyAddButton/MyAddButton";
import MyInputLabel from "../../../../../../../../components/UI/MyInputLabel/MyInputLabel";
import SentenceCard from "./SentenceCard/SentenceCard";
import { QuestionsLabel } from "../../../Preview/components";
import cl from "./SentenceOrderEditor.module.css";

const SentenceOrderEditor = ({ exerciseData, onChange }) => {
  const { t } = useTranslation();
  const sentences = exerciseData.sentences || [];

  const [isAddDisabled, setIsAddDisabled] = useState(false);

  const handleSentenceAdd = () => {
    onChange({ sentences: [...sentences, { id: uuid(), text: "" }] });
  };

  const handleSentenceUpdate = (index, sentence) => {
    const newSentences = [...sentences];
    newSentences[index].text = sentence;
    onChange({ sentences: newSentences });
  };

  const handleSentenceDelete = (index) => {
    const newSentences = [...sentences];
    if (newSentences.length === 1) {
      newSentences[0] = { ...newSentences[0], text: "" };
      onChange({ sentences: newSentences });
      return;
    }
    newSentences.splice(index, 1);
    onChange({ sentences: newSentences });
  };

  const handleSentenceMove = (dragIndex, hoverIndex) => {
    const newSentences = [...sentences];
    const [removedSentence] = newSentences.splice(dragIndex, 1);
    newSentences.splice(hoverIndex, 0, removedSentence);
    onChange({ sentences: newSentences });
  };

  useEffect(() => {
    if (sentences[sentences.length - 1]) {
      setIsAddDisabled(!sentences[sentences.length - 1].text?.length);
    }
  }, [sentences]);

  return (
    <div className={cl.sentenceOrderEditor}>
      <QuestionsLabel label={"edit_sentences_order"} />
      <div className={cl.sentenceOrderInner}>
        {sentences.length ? (
          <DndProvider backend={HTML5Backend}>
            {sentences.map((s, idx) => (
              <SentenceCard
                key={s.id}
                id={s.id}
                idx={idx}
                text={s.text ?? ""}
                onDrag={handleSentenceMove}
                onChange={handleSentenceUpdate}
                onDelete={handleSentenceDelete}
              />
            ))}
          </DndProvider>
        ) : (
          <MyInputLabel text={t("exercises.no_sentences")} />
        )}
        <MyAddButton
          text={t(
            sentences.length
              ? "exercises.new_sentence"
              : "exercises.first_sentence"
          )}
          onClick={handleSentenceAdd}
          isDisabled={isAddDisabled}
        />
      </div>
    </div>
  );
};

export default observer(SentenceOrderEditor);
