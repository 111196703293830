import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useFetching } from "../../../../../../hooks/useFetching";
import exercisesPlayer from "../../../../store/exercisesPlayer";
import exercisesStore from "../../../../store/exercisesStore";
import TrackService from "../../../../../../api/TrackService";
import { ExercisePlayer, ExercisePlayerControls } from "..";
import { Loader } from "../../../../../../UI";
import cl from "./EditMedia.module.css";

const EditMedia = ({ controls }) => {
  const { currentTrack } = exercisesStore;

  const [getTrack, trackLoading] = useFetching(async () => {
    const { data } = await TrackService.getTrackSentences({ id: currentTrack });
    exercisesPlayer.setTrackData(data);
    exercisesStore.setTrackText(
      data?.sentences.map((s) => s.text).join(" ") ?? ""
    );
  });

  useEffect(() => {
    if (exercisesStore.currentTrack && exercisesStore.trackText) return;
    getTrack();
  }, [currentTrack]);

  if (trackLoading) return <Loader />;

  return (
    <>
      <div className={cl.mediaEditor} key={currentTrack}>
        <div className={cl.ytContainer}>
          <ExercisePlayer isPreview={false} />
          <p className={cl.textContainer}>{exercisesStore.trackText}</p>
        </div>

        <div className={cl.controlsContainer}>
          <ExercisePlayerControls
            mode={"video"}
            isPreview={false}
            controls={controls}
          />
        </div>
      </div>
    </>
  );
};

export default observer(EditMedia);
