import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Range,
  Select,
  ToggleButton,
} from "../../../../../../teacherComponents";
import MyAdjustableTextarea from "../../../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import { useFetching } from "../../../../../../hooks/useFetching";
import ExerciseService from "../../../../../../api/ExerciseService";
import exercisesStore from "../../../../store/exercisesStore";
import { MediaTypes, TextRange, TextGenres } from "../../../../data/constants";
import { LanguageLevels } from "../../../../../../data/common";
import cl from "./GenerateText.module.css";
import SelectTargetVocabulary from "../SelectTargetVocabulary/SelectTargetVocabulary";

const DefaultTextParams = {
  difficulty: "A1",
  vocabulary: "",
  genre: TextGenres.Text,
  grammarTopic: "",
  textTopic: "",
  sentencesAmount: 10,
};

const DefaultTextErrors = Object.fromEntries(
  Object.keys(DefaultTextParams).map((key) => [key, false])
);

const GenerateText = ({ mediaType }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [modeSelect, setModeSelect] = useState(true);
  const [textParams, setTextParams] = useState(DefaultTextParams);
  const [textErrors, setTextErrors] = useState(DefaultTextErrors);
  const [vocabularyModal, setVocabularyModal] = useState(false);

  const [generateText, generateLoading] = useFetching(async () => {
    const { data } = await ExerciseService.generateText({
      lang,
      difficulty: LanguageLevels.indexOf(textParams.difficulty),
      grammarTopic: textParams.grammarTopic,
      textTopic: textParams.textTopic,
      genre: textParams.genre,
      vocabulary: textParams.vocabulary.split(/ .,!?/),
      sentencesAmount: textParams.sentencesAmount,
    });

    exercisesStore.setGeneratedText(data.text);
  });

  const setSentencesAmount = (e) => {
    setTextParams({ ...textParams, sentencesAmount: e.target.value });
  };

  const handleTextGenerate = () => {
    const errors = {
      textTopic: !textParams.textTopic,
      grammarTopic: !textParams.grammarTopic,
      sentencesAmount: !textParams.sentencesAmount,
      difficulty: !textParams.difficulty,
    };
    setTextErrors(errors);

    if (Object.values(errors).some((e) => e)) return;

    generateText();
  };

  useEffect(() => {
    if (exercisesStore.generatedText === null) {
      setTextParams(DefaultTextParams);
    }
  }, [exercisesStore.generatedText]);

  useEffect(() => {
    setTextErrors(DefaultTextErrors);
  }, [textParams]);

  if (mediaType !== MediaTypes.GeneratedText) return null;

  if (modeSelect)
    return (
      <>
        <p className={cl.label} style={{ margin: "10px auto" }}>
          {t("exercises.generating_text_info")}
        </p>
        <div className={cl.modeButtonsContainer}>
          <Button
            variant={"purple"}
            text={t("exercises.generate_text")}
            onClick={() => setModeSelect(false)}
          />
          <p className={cl.label} style={{ margin: 0 }}>
            {t("exercises.or")}
          </p>
          <Button
            variant={"purple"}
            text={t("exercises.use_own")}
            onClick={() => {
              exercisesStore.setGeneratedText("");
              setModeSelect(false);
            }}
          />
        </div>
      </>
    );

  return exercisesStore.generatedText !== null ? (
    <>
      <MyAdjustableTextarea
        placeholder={t("exercises.media_placeholder")}
        value={exercisesStore.generatedText ?? ""}
        onChange={exercisesStore.setGeneratedText}
        className={cl.text}
      />
    </>
  ) : (
    <div className={cl.generateText}>
      <div className={cl.horizontalContainer}>
        <div>
          <p className={cl.label}>{t("exercises.genre")}</p>
          <Select
            style={{ width: 212, marginRight: "auto" }}
            label={t("exercises.genre")}
            variant={"light"}
            value={textParams.genre}
            options={Object.values(TextGenres).map((g) => ({
              value: g,
              name: t(`exercises.${g}`),
            }))}
            onChange={(genre) => setTextParams({ ...textParams, genre })}
            height={250}
          />
        </div>
        <div>
          <p className={cl.label}>{t("exercises.text_level")}</p>
          <ToggleButton
            style={{ width: 212 }}
            label={t("users.level")}
            options={LanguageLevels}
            selectedOption={textParams.difficulty}
            variant={"filled"}
            setOption={(difficulty) =>
              setTextParams({ ...textParams, difficulty })
            }
          />
        </div>
      </div>
      <div>
        <p className={cl.label}>{t("exercises.topic")}</p>
        <Input
          variant={textErrors["textTopic"] ? "erroredSmall" : "outlinedSmall"}
          placeholder={t("exercises.topic_example")}
          value={textParams.textTopic}
          onChange={(textTopic) => setTextParams({ ...textParams, textTopic })}
        />
      </div>
      <div>
        <p className={cl.label}>{t("exercises.text_theme")}</p>
        <Input
          variant={
            textErrors["grammarTopic"] ? "erroredSmall" : "outlinedSmall"
          }
          placeholder={t("exercises.text_theme_example")}
          value={textParams.grammarTopic}
          onChange={(grammarTopic) =>
            setTextParams({ ...textParams, grammarTopic })
          }
        />
      </div>
      <div>
        <p className={cl.label}>{`${t("exercises.vocabulary")}  -  ${t(
          "exercises.vocabulary_placeholder"
        )}`}</p>
        <div className={cl.inputContainer}>
          <Input
            variant={"outlinedSmall"}
            label={t("exercises.vocabulary")}
            placeholder={"social media, communication, information..."}
            value={textParams.vocabulary}
            onChange={(vocabulary) =>
              setTextParams({ ...textParams, vocabulary })
            }
          />
          <Button
            variant={"purple"}
            text={t("exercises.use_word_pack")}
            onClick={() => setVocabularyModal(true)}
          />
        </div>
      </div>

      <p className={cl.rangeLabel}>{`${t("exercises.text_sentence_count")} (${
        textParams.sentencesAmount ?? DefaultTextParams.sentencesAmount
      })`}</p>
      <Range
        value={textParams.sentencesAmount}
        min={TextRange.Min}
        step={TextRange.Step}
        max={TextRange.Max}
        onChange={setSentencesAmount}
      />
      <div className={cl.buttonsContainer}>
        <Button
          style={{ margin: "auto" }}
          text={t("exercises.generate_text")}
          variant={"purple"}
          onClick={handleTextGenerate}
          isLoading={generateLoading}
        />
      </div>
      <SelectTargetVocabulary
        visible={vocabularyModal}
        setVisible={setVocabularyModal}
        onSelect={(vocabulary) => setTextParams({ ...textParams, vocabulary })}
      />
    </div>
  );
};

export default observer(GenerateText);
