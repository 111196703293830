import React, { useEffect, useState } from "react";
import shuffleArray from "../../../../../../../../utils/shuffleArray";
import cl from "./WordsOrderPreview.module.css";
import QuestionsLabel from "../QuestionsLabel/QuestionsLabel";

const WordsOrderPreview = ({ exerciseObj, results, showAnswers }) => {
  const { data } = exerciseObj;
  const { sentences } = data;

  const [formatedSentences, setFormatedSenteces] = useState([]);
  const [variants, setVariants] = useState([]);
  const [answers, setAnswers] = useState([]);

  const handleAnswerClick = (sentenceIdx, answer) => {
    const newAnswers = [...answers];
    const pos = newAnswers[sentenceIdx].findIndex((w) => !w.pos);
    newAnswers[sentenceIdx][pos] = answer;
    setAnswers(newAnswers);
    const newVariants = [...variants];
    newVariants[sentenceIdx].words = newVariants[sentenceIdx].words.filter(
      (w) => w.pos !== answer.pos
    );
    setVariants(newVariants);
  };

  const handleAnswerRemove = (sentenceIdx, answer) => {
    if (!answer.pos) return;
    const newAnswers = [...answers];
    const pos = newAnswers[sentenceIdx].findIndex((w) => w.pos === answer.pos);
    newAnswers[sentenceIdx][pos] = "";
    setAnswers(newAnswers);
    const newVariants = [...variants];
    newVariants[sentenceIdx].words = [
      answer,
      ...newVariants[sentenceIdx].words,
    ];
    setVariants(newVariants);
  };

  const displayAnswers = () => {
    setAnswers(formatedSentences.map((s) => s.words));
    setVariants(variants.map((v) => ({ ...v, words: [] })));
  };

  const resetAnswers = () => {
    setAnswers(
      new Array(formatedSentences.length)
        .fill("")
        .map((_a, i) => new Array(formatedSentences[i].words?.length).fill(""))
    );
    setVariants(
      formatedSentences.map((s) => ({
        ...s,
        words: shuffleArray(s?.words ?? []),
      }))
    );
  };

  useEffect(() => {
    showAnswers ? displayAnswers() : resetAnswers();
  }, [showAnswers]);

  useEffect(() => {
    if (!sentences || !sentences.length) return;
    const formatedSentences = sentences.map((s) => ({
      id: s.id,
      words: s.text
        ?.split(" ")
        .filter((w) => w !== " " && w.length)
        .map((w, i) => ({ text: w, pos: i + 1 })),
    }));
    setFormatedSenteces(formatedSentences);
    if (!results) {
      setAnswers(
        new Array(formatedSentences.length)
          .fill("")
          .map((_a, i) =>
            new Array(formatedSentences[i].words?.length).fill("")
          )
      );
      setVariants(
        formatedSentences.map((s) => ({
          ...s,
          words: shuffleArray(s?.words ?? []),
        }))
      );
    } else {
      setVariants(
        formatedSentences.map((s) => ({
          ...s,
          words: [],
        }))
      );
      setAnswers(
        results.filledSentences?.map((o) =>
          o.map((s, i) => ({ text: s, pos: i }))
        ) || []
      );
    }
  }, [sentences, results]);

  const getIsCorrect = (sIdx, aIdx, answer) => {
    if (!answer) return "";
    return formatedSentences[sIdx].words[aIdx].text === answer.text
      ? cl.correct
      : cl.wrong;
  };

  return (
    <div className={cl.sentencesCont}>
      <QuestionsLabel label={results ? "student_answers" : "statements"} />
      {variants?.map((s, idx) => (
        <div className={cl.sentence} key={idx}>
          <div className={cl.text}>
            {answers[idx]?.map((w, i) => (
              <span
                className={`${cl.answer} ${getIsCorrect(idx, i, w)}`}
                key={`${w}${i}`}
                onClick={results ? () => {} : () => handleAnswerRemove(idx, w)}
              >
                {w.text}
                {w.endChar}
              </span>
            ))}
          </div>
          {s.words.length !== 0 && (
            <div className={cl.variantsCont}>
              {s.words.map((w, i) => (
                <span
                  className={cl.variant}
                  key={`${w}${i}`}
                  onClick={() => handleAnswerClick(idx, w)}
                >
                  {w.text}
                  {w.endChar}
                </span>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default WordsOrderPreview;
