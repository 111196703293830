import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../../../hooks/useFetching";
import ExerciseService from "../../../../../../api/ExerciseService";
import exercisesStore from "../../../../store/exercisesStore";
import {
  Button,
  CloseButton,
  Input,
  ToggleButton,
} from "../../../../../../teacherComponents";
import {
  AdvancedQuizEditor,
  ClozeEditor,
  MultichoiceEditor,
  QuestionsEditor,
  QuizEditor,
  SentenceOrderEditor,
  SubstitutionEditor,
  SummaryEditor,
  WordsOrderEditor,
} from "./components";
import {
  GrammarExerciseTypes,
  MediaExerciseTypes,
} from "../../../../data/constants";
import { LanguageLevels } from "../../../../../../data/common";
import MyAdjustableTextarea from "../../../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import { DeleteConfirmation } from "../../../../../TeacherLessons/components";
import MyModal from "../../../../../../components/UI/MyModal/MyModal";
import cl from "./EditExerciseModal.module.css";

const EditExerciseModal = () => {
  const { t } = useTranslation();

  const { currentExercise, editMode } = exercisesStore;
  const { id, type, categoryId, difficulty, title, description } =
    currentExercise;

  const [exerciseData, setExerciseData] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [getExerciseData] = useFetching(async () => {
    if (!id) return;
    const { data: exerciseResponse } = await ExerciseService.getExercise({
      id,
    });
    setExerciseData(exerciseResponse?.data);
  });

  const [updateExercise, updateLoading] = useFetching(async () => {
    const { data } = await ExerciseService.updateExercise({
      id,
      type,
      categoryId,
      difficulty,
      title,
      data: exerciseData,
      description,
    });
    exercisesStore.updateExercise(data);
  });

  const [deleteExercise, deleteLoading] = useFetching(async () => {
    const res = await ExerciseService.deleteExercise({ id });
    if (res) {
      exercisesStore.deleteExercise(id);
    }
  });

  const handleTitleChange = (title) => {
    exercisesStore.updateCurrentExercise({ title });
  };

  const handleDescChange = (description) => {
    exercisesStore.updateCurrentExercise({ description });
  };

  const handleDifficultyChange = (level) => {
    const difficulty = LanguageLevels.indexOf(level);
    exercisesStore.updateCurrentExercise({ difficulty });
  };

  const renderEditor = () => {
    switch (type) {
      case GrammarExerciseTypes.Multichoice:
        return (
          <MultichoiceEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );
      case GrammarExerciseTypes.Cloze:
        return (
          <ClozeEditor exerciseData={exerciseData} onChange={setExerciseData} />
        );
      case GrammarExerciseTypes.Substitution:
        return (
          <SubstitutionEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );
      case GrammarExerciseTypes.WordsOrder:
        return (
          <WordsOrderEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );
      case MediaExerciseTypes.FreeFormQuestions:
      case GrammarExerciseTypes.StatementsTransformation:
        return (
          <QuestionsEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );
      case MediaExerciseTypes.Quiz:
        return (
          <QuizEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
            disableSave={setSaveDisabled}
          />
        );
      case MediaExerciseTypes.SentencesOrder:
        return (
          <SentenceOrderEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );

      case MediaExerciseTypes.Summary:
        return (
          <SummaryEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );
      case MediaExerciseTypes.AdvancedQuiz:
        return (
          <AdvancedQuizEditor
            exerciseData={exerciseData}
            disableSave={setSaveDisabled}
            onChange={setExerciseData}
          />
        );
      default:
        return <></>;
    }
  };

  const isSaveDisabled = () => {
    if (!title.length) return true;
    if (saveDisabled) return true;
    return false;
  };

  const handleDeleteButton = (e) => {
    e.stopPropagation();
    setIsDelete(true);
  };

  useEffect(() => {
    setExerciseData({});
    if (!currentExercise.id) return;
    setSaveDisabled(false);
    getExerciseData();
    return () => {
      exercisesStore.setEditMode(false);
    };
  }, [currentExercise.id]);

  return (
    currentExercise.id && (
      <MyModal
        visible={editMode}
        setVisible={exercisesStore.setEditMode}
        className={cl.editModal}
      >
        <p className={cl.title}>{t("exercises.edit_exercise")}</p>
        <div className={cl.editorContainer}>
          <div className={cl.horizontalCont}>
            <div style={{ width: "100%" }}>
              <p className={cl.label}> {t("exercises.exercise_title")}</p>
              <Input
                variant={"outlinedSmall"}
                style={{ width: "100%" }}
                value={title}
                placeholder={t("exercises.title_placeholder")}
                onChange={handleTitleChange}
              />
            </div>
            <ToggleButton
              options={LanguageLevels}
              selectedOption={LanguageLevels[difficulty]}
              setOption={handleDifficultyChange}
              variant={"filled"}
            />
          </div>
          <div>
            <p className={cl.label}>{t("exercises.exercise_explanatory")}</p>
            <MyAdjustableTextarea
              value={description}
              placeholder={t("exercises.explanatory_placeholder")}
              onChange={handleDescChange}
              style={{ fontSize: 12 }}
            />
          </div>
          {renderEditor()}
        </div>
        <div className={cl.buttonsContainer}>
          <Button
            text={t("buttons.save")}
            variant={"dark"}
            onClick={updateExercise}
            isLoading={updateLoading}
            isDisabled={isSaveDisabled()}
          />
          <Button
            text={t("buttons.close")}
            variant={"grey"}
            onClick={() => exercisesStore.setEditMode(false)}
          />
          <Button
            text={t("buttons.delete")}
            variant={"red"}
            onClick={handleDeleteButton}
            isLoading={deleteLoading}
            style={{ marginLeft: "auto" }}
          />
        </div>
        <DeleteConfirmation
          title={t(`exercises.delete_exercise_confirmation_title`)}
          visible={isDelete}
          setVisible={setIsDelete}
          onDelete={deleteExercise}
        />
        <CloseButton onClick={() => exercisesStore.setEditMode(false)} />
      </MyModal>
    )
  );
};

export default observer(EditExerciseModal);
