import React from "react";
import cl from "./StudentCalendar.module.css";

const StudentCalendar = () => {
  return (
    <div style={{ margin: "auto" }}>
      <p>{"this view is not implemented yet"}</p>
    </div>
  );
};

export default StudentCalendar;
