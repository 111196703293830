import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import exercisesStore from "../../../TeacherContent/store/exercisesStore";
import lessonsStore from "../../store/lessonsStore";
import { useFetching } from "../../../../hooks/useFetching";
import ExerciseService from "../../../../api/ExerciseService";
import {
  AgeOptions,
  getExamOptions,
  LevelOptions,
} from "../../../TeacherContent/data/constants";
import { PrimaryInput } from "../../../../UI";
import { Select } from "../../../../teacherComponents";
import cl from "./AddLesson.module.css";

const AddLesson = ({ themeObj, forceAdd }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { currentStudent } = exercisesStore;

  const { id: themeId, title: themeTitle } = themeObj;

  const ref = useRef();

  const [isAdd, setIsAdd] = useState(false);
  const [title, setTitle] = useState();
  const [age, setAge] = useState();
  const [difficulty, setDifficulty] = useState();
  const [exam, setExam] = useState();

  const [addLesson, addLoading] = useFetching(async () => {
    const { data } = await ExerciseService.createLesson({
      title,
      lang,
      topic: themeTitle,
      difficulty,
      age,
      exam,
    });

    lessonsStore.addLesson(themeId, {
      id: data.id,
      title,
      lessons: [],
      age,
      difficulty,
    });
    setIsAdd(false);
  });

  const handleAddClick = () => {
    if (currentStudent) return;
    setIsAdd(true);
  };

  useEffect(() => {
    setTitle("");
    setAge();
    setDifficulty();
  }, [isAdd]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (title) addLesson();
        setIsAdd(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, title, age, difficulty]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        if (title) addLesson();
        setIsAdd(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [title, age, difficulty]);

  useEffect(() => {
    setIsAdd(forceAdd);
  }, [forceAdd]);

  return (
    <div
      ref={ref}
      className={`${cl.buttonsContainer} ${currentStudent ? cl.blocked : ""}`}
    >
      {isAdd ? (
        <div className={cl.emptyLesson}>
          <PrimaryInput
            placeholder={t("exercises.lesson_title")}
            style={{ width: "100%" }}
            value={title}
            onChange={setTitle}
            autoFocus={true}
          />
          <div className={cl.selectContainer}>
            <Select
              variant={"light"}
              label={t("users.age")}
              value={age}
              options={AgeOptions}
              onChange={setAge}
            />
            <Select
              variant={"light"}
              label={t("users.level")}
              value={difficulty}
              options={LevelOptions}
              onChange={setDifficulty}
            />
            <Select
              variant={"light"}
              label={t("exercises.exam")}
              value={exam}
              options={getExamOptions(lang)}
              onChange={setExam}
            />
          </div>
        </div>
      ) : (
        <p className={cl.addButton} onClick={handleAddClick}>
          {`+ ${t("exercises.add_lesson")}`}
          <span className={cl.tooltip}>{t("exercises.unselect_student")}</span>
        </p>
      )}
    </div>
  );
};

export default observer(AddLesson);
